import { Extension } from '@tiptap/react';
import { Node } from '@tiptap/core';

export const TAB_CHAR = '\u0009';

export const TabHandler = Extension.create({
    name: 'tabHandler',
    addKeyboardShortcuts() {
      return {
        Tab: ({ editor }) => {
          editor
            .chain()
            .sinkListItem('listItem')
            .command(({ tr }) => {
              tr.insertText(TAB_CHAR);
              return true;
            })
            .run();
          return true;
        },
      };
    },
  });

  export const StyledNode = Node.create({
    name: 'span',

    group: 'inline',

    inline: true,

    atom: true,

    addAttributes() {
      return {
        style: {
          default: null,
          parseHTML: (element) => element.getAttribute('style'),
          renderHTML: (attributes) => {
            if (!attributes.style) return {};
            return { style: attributes.style };
          },
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: 'span',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return ['span', HTMLAttributes, 0];
    },
  });

export default TabHandler;
