/* eslint-disable no-return-assign */
import { format } from 'date-fns';
import moment from 'moment';
import Lang from 'src/libraries/language';
import {
  REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
  REAL_TIME_LOG_EMAIL_FIELD_LABEL,
  RealTimeLogAuditCategory,
} from 'src/constants/real-time-log.constant';
import {
  IForcedOutageConfirmationForm,
  IRealTimeLog,
  IRealTimeLogFieldModel,
} from 'src/models/real-time-log.model';

export const dateTimeFormat = 'MMM dd, yyyy (EEE) HH:mm';
export const timeFormat = 'HH:mm';
export const removeComma = (data: any) => {
  return data?.toString()?.replace(/,/g, '') ?? '';
};

export const generateRealTimeLogEmailFormDetails = (
  data: Partial<IRealTimeLog>,
  fullDetails = false
): IForcedOutageConfirmationForm => {
  let content = '';
  let subject = '';
  let historyContent = '';

  const sentAuditLog = data.auditLogs?.find(
    (i) => i.auditType === RealTimeLogAuditCategory.EMAIL_SENT
  );

  subject = sentAuditLog
    ? 'Update Real-Time Interruption: '
    : 'Real-Time Interruption: ';

  if (fullDetails) {
    const NL = '\n';
    const mailBody: Record<
      REAL_TIME_LOG_EMAIL_FIELD_LABEL,
      IRealTimeLogFieldModel
    > = {
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME,
        fieldValue: data.logDtTm ? format(data.logDtTm, dateTimeFormat) : '',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY,
        fieldValue: data.logCategory,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE,
        fieldValue: data.facility?.outgFacNm,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION,
        fieldValue: `${data.substation?.name as any} ${NL}${
          REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT
        } ${data.facility?.outgFacNm} - ${data.facility?.volt?.voltNm}kV - ${
          data.facility?.facTyp?.facTypNm
        }`,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT_TYPE]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT_TYPE,
        fieldValue: data.facility?.facTyp?.facTypNm,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.VOLTAGE]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.VOLTAGE,
        fieldValue: `${data.facility?.volt?.voltNm}kV`,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT,
        fieldValue: data.facility?.outgFacNm,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED,
        fieldValue: data.forcedLogSubs?.length
          ? `${data?.forcedLogSubs
              ?.map((val) => ` ${val.substation.name}`)
              .toString()
              .trim()}`
          : 'None',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED,
        fieldValue: data.logDtTmFrcdOutOcurred
          ? format(data.logDtTmFrcdOutOcurred, dateTimeFormat)
          : '',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED,
        fieldValue: data.logDtTmFrcdOutRestored
          ? format(data.logDtTmFrcdOutRestored, dateTimeFormat)
          : '',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY,
        fieldValue: data.logEntry?.length ? data.logEntry : '',
      },
    };

    Object.keys(mailBody).forEach((keyValue) => {
      const field = mailBody[keyValue as REAL_TIME_LOG_EMAIL_FIELD_LABEL];

      switch (keyValue) {
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME:
          content += `${field.fieldTitle} ${field.fieldValue}\n`;
          break;
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY: {
          content += `${field.fieldTitle} ${field.fieldValue}\n`;
          content += '\n';
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE: {
          if (!data.substation) {
            subject += `${data.facility?.volt?.voltNm}kV ${data.facility?.outgFacNm}`;
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
            content += '\n';
          }
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION: {
          if (data.substation) {
            subject += `${data.facility?.volt?.voltNm}kV ${
              data.substation?.name as any
            }`;
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
            content += '\n';
          }
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED:
          if (field.fieldValue?.length) {
            content += `${field.fieldTitle}\n${field.fieldValue}\n`;
          }
          break;
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED:
          if (field.fieldValue?.length) {
            content += '\n';
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
          }
          break;
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED: {
          if (field.fieldValue?.length) {
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
          }
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY:
          if (field.fieldValue?.length) {
            content += '\n';
            content += `${field.fieldTitle}\n${field.fieldValue}`;
            content += '\n';
          }
          break;
        default:
          break;
      }
    });
  } else {
    let kvFacility = '';
    if (!data.substation) {
      kvFacility += `${data.facility?.volt?.voltNm}kV ${data.facility?.outgFacNm}`;
    }

    if (data.substation) {
      kvFacility += `${data.facility?.volt?.voltNm}kV ${
        data.substation?.name as any
      }`;
    }

    subject += kvFacility;

    const subsInterrupted = data.forcedLogSubs?.length
      ? `${data?.forcedLogSubs
          ?.map((val) => ` ${val.substation.name}`)
          .toString()
          .trim()}`
      : null;

    const subsLength = data.forcedLogSubs?.length ?? 0;

    content += `At ${
      data.logDtTmFrcdOutOcurred
        ? data.logDtTmFrcdOutOcurred.getHours().toString().padStart(2, '0') +
          data.logDtTmFrcdOutOcurred.getMinutes().toString().padStart(2, '0')
        : '--'
    } ${kvFacility} Relayed/Tripped/Opened. Cause under investigation. CAISO and SCM/TCM/SPM notified. `;

    if (subsInterrupted) {
      content += `{The ${subsInterrupted} substation${
        subsLength > 1 ? 's' : ''
      } `;
      content += `${subsLength > 1 ? 'were' : 'was'} dropped.}`;
    }
  }

  if (data.auditLogs && data.auditLogs.length > 0) {
    const { length } = data.auditLogs;
    data.auditLogs.map((item, index) => {
      if (item.emailContent) {
        if (index !== length - 1) {
          historyContent += `${format(item.updatedAt, dateTimeFormat)}: ${
            item.emailContent
          }\n `;
        } else {
          historyContent += `${format(item.updatedAt, dateTimeFormat)}: ${
            item.emailContent
          }`;
        }
      }
    });
  }

  return {
    logId: data.logId as number,
    mailSubject: subject,
    mailContent: content,
    mailHistoryContent: historyContent,
  };
};

  // BPOR Report
  export const generateRealTimeLogBPORReport = (
    data: Partial<IRealTimeLog[]>,
    date: Date
  ): IForcedOutageConfirmationForm => {
    const NONE = 'None';
    const sortedData = data?.sort((a: any, b: any) => a?.logCategory?.toLowerCase()?.localeCompare(b?.logCategory.toLowerCase()));
    const extractReport = (bporReport: IRealTimeLog) => {
    const terminalRegex = /\(([^)]+)\)/g;
    const tlRegx = /\d+/g;
    const getFacilityInfo = (isTerminal = false) => {
      let tl = NONE;
      const facilityName = bporReport?.facility?.outgFacNm?.trim();
      let terminals = facilityName;
      const getFacility = (splitBy) => facilityName?.split(splitBy);
      let facility: any[] = [];
      if (getFacility(' ')?.length) {
        facility = getFacility(' ') as any;
      for (let i = 0; i < facility.length; i++) {
        if (facility[i].match(tlRegx) || facility[i].match(/\d+kV/g)) {
          tl = facility[i];
          break;
        }
      }
    }
    if (terminals?.match(/\bat\b/gi)) {
      terminals = terminals.slice(terminals?.indexOf(' at ') + 4, terminals.length);
    }
    if (facilityName?.match(terminalRegex)) {
      const matchResult = facilityName?.match(terminalRegex);
      terminals = matchResult?.length ? matchResult[0].replace(/[()]/g, '') : NONE;
    }
    if (isTerminal) {
      if (terminals?.match(/TL\s\d+/gi)) {
        const match = terminals?.match(/TL\s\d+/gi);
        if (match?.length) {
          terminals = terminals.slice(terminals?.indexOf(match[0]) + match[0].length, terminals.length);
        }
      }
      return terminals?.trim() ?? NONE;
    }
    return tl;
    };
    const mailBody: Record<
      REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
      IRealTimeLogFieldModel
    > = {
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST,
        fieldValue: '',
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS,
        fieldValue: '',
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL,
        fieldValue: bporReport.facility?.outgFacNm ? getFacilityInfo() : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS,
        fieldValue: getFacilityInfo(true),
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED,
        fieldValue: bporReport.forcedLogSubs?.length
        ? `${bporReport?.forcedLogSubs
            ?.map((val) => ` ${val.substation.name}`)
            ?.toString()
            ?.trim()}`
        : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED,
        fieldValue: bporReport.logDtTmFrcdOutOcurred
        ? format(bporReport.logDtTmFrcdOutOcurred, timeFormat)?.replace(/:/g, '')
        : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED,
        fieldValue: bporReport.logDtTmFrcdOutRestored
        ? format(bporReport.logDtTmFrcdOutRestored, timeFormat)?.replace(/:/g, '')
        : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE,
        fieldValue: NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS,
        fieldValue: bporReport.forcedLog?.length ? bporReport.forcedLog?.map(
            (val) => `${val?.substation?.name ?? ''}: ${
                val?.tripsTargets ?? ''
              }`
          ) as any
          : NONE,
      },
    };
    return mailBody;
    };

    const generateHTML = (fieldTitle: string,
      fieldvalue: string | null | undefined,
      record: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL) => {
      let generatedContent = '';
      const getContent = () => {
        const generateItemsOfInterests = () => {
          let items = '';
          sortedData?.map((val) => {
            switch (val?.logCategory) {
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['30+min Unplanned Telemetering/Control Equip Outage']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['Change Control Center']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['RC/BA Notification']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['Int./Freq. Excursion']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['Field Notification']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['Emergency Event']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS['Comm. Failure']]:
              case Lang.REAL_TIME_LOG_STATUS[Lang.REAL_TIME_LOG_STATUS.Wildfire]: {
                const logEntries = () => {
                  let list = '';
                  val?.logEntry?.split(/\n/g)?.map((value) => {
                    list += `<li>${value}</li>`;
                  });
                  return list;
                };
                const entries = logEntries();
                items += `<p>${val.logCategory} </p>${entries.length ? `<ul>${logEntries()}</ul>` : ''}`;
                break;
              }
              default: break;
            }
          });
          return items.length ? items : '<p>Nothing to report.</p>';
        };

        if (record === REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST) {
          return generateItemsOfInterests();
        }
        return `<p><span class="custom-span" custom-style="display: inline-block;width: 42.5%;position: static;margin: 0; padding: 0">${fieldTitle}</span>${fieldvalue}</p>`;
      };
      switch (record) {
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST: {
          generatedContent += `<p><strong><span style="color: #031858"><u>${fieldTitle}</u></span></strong></p>`;
          generatedContent += `${getContent()}<p />`;
          break;
        }
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS: {
          generatedContent += `<p><strong><span style="color: #031858"><u>${fieldTitle}</u></span></strong></p>`;
          break;
        }
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL:
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS:
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED:
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED:
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED:
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE:
        case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS: {
          generatedContent += getContent();
          break;
        }
        default:
          break;
      }
      return generatedContent;
    };

    const report: Record<REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL, IRealTimeLogFieldModel>[] = [];
    sortedData?.map((val) => {
      const extractedReport = val && extractReport(val);
      return extractedReport && report.push(extractedReport);
    });

  let content = data.length ? `<p style="text-align: center">${date
    ? moment(date).format('dddd, MMMM D, YYYY') : ''}</p>`
    : '<p style="text-align: center">No records found</p>';
    const subject = 'Real-Time Interruption: ';

    report.map((mailBody, i) => {
      Object.keys(mailBody).forEach((keyValue) => {
        const field = mailBody[keyValue as REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL];
        switch (keyValue) {
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS: {
            if (i === 0) {
              content += generateHTML(field.fieldTitle, field?.fieldValue, keyValue);
            }
            break;
          }
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE:
            content += generateHTML(field.fieldTitle, field?.fieldValue, keyValue);
            break;
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS:
            content += generateHTML(field.fieldTitle, field?.fieldValue, keyValue);
            if (data.length > 1 && report.length - 1 !== i) {
              content += '<hr />';
            }
          break;
          default:
            break;
        }
      });
    });

    return {
      logId: 0,
      mailSubject: subject,
      mailContent: content,
      mailHistoryContent: undefined,
    };
  };
