import moment from 'moment';
import Moment, { unitOfTime } from 'moment-timezone';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'HH:mm';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    w: '%dw',
    ww: '%dw',
    M: '%dM',
    MM: '%dM',
    y: '%dy',
    yy: '%dy',
  },
});

export const formatDates = (value: any) => {
  if (value.length > 1) {
    const startDate = new Date(
      moment(value[0]).utc(true).format('YYYY-MM-DDT00:00:00.000[Z]')
    );
    startDate.setUTCHours(7);
    const start = startDate.toISOString();
    const endDate = new Date(
      moment(value[1]).utc(true).format('YYYY-MM-DDT23:59:59.000[Z]')
    );
    endDate.setUTCHours(endDate.getUTCHours() + 7);
    const end = endDate.toISOString();
    return [start, end];
  }
  return [new Date()];
};

export const formatDate = (
  value?: moment.Moment | Date | string | null,
  format?: string
) => {
  const parsedDate = moment(value, format);

  return value && parsedDate.isValid() ? parsedDate.format(DATE_FORMAT) : null;
};

export const formatDateTime = (
  value?: moment.Moment | Date | string | null
) => {
  if (!value) {
    return null;
  }

  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(`${DATE_FORMAT} hh:mm a`)
    : null;
};

export const formatDateTimeSec = (
  value?: moment.Moment | Date | string | null
) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(`${DATE_FORMAT} hh:mm:ss`)
    : null;
};

export const formatTime = (value?: moment.Moment | Date | string | null) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid() ? parsedDate.format('hh:mm') : null;
};

export const format24hTime = (value?: moment.Moment | Date | string | null) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(DATE_TIME_FORMAT)
    : null;
};

export const format24hDateTime = (
  value?: moment.Moment | Date | string | null
) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(`${DATE_FORMAT} ${DATE_TIME_FORMAT}`)
    : null;
};

export const formatTimeOnly = (
  value?: moment.Moment | Date | string | null
) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(DATE_TIME_FORMAT)
    : null;
};

export const formatDateTimeOnly = (
  value?: moment.Moment | Date | string | null
) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(`${DATE_FORMAT} - hh:mm`)
    : null;
};

export const formatDateHour = (
  value?: moment.Moment | Date | string | null
) => {
  const parsedDate = moment(value);

  return value && parsedDate.isValid()
    ? parsedDate.format(`${DATE_FORMAT} HH:mm`)
    : null;
};

export const formatDateTz = (value?: moment.Moment | Date | string | null) => {
  const parsedDate = moment(value).utc(true);

  return value && parsedDate.isValid() ? parsedDate.format(DATE_FORMAT) : null;
};

export const createDateTime = (
  date: Date | IMoment,
  time: string,
  allDay?: boolean
) =>
  allDay
    ? Moment(date).startOf('day')
    : Moment(date).set({
        hour: Number(time.split(':')[0]),
        minute: Number(time.split(':')[1]),
        second: 0,
      });

export const dateInEventRange = ({
  event: { start, end },
  range: { start: rangeStart, end: rangeEnd },
}: Record<
  'event' | 'range',
  {
    start: IMoment;
    end: IMoment;
  }
>) => {
  const startOfDay = Moment(start).startOf('day');
  const eEnd = Moment(end);
  const rStart = Moment(rangeStart);
  const rEnd = Moment(rangeEnd);

  const startsBeforeEnd = startOfDay.isSameOrBefore(rEnd, 'day');

  const sameMin = !startOfDay.isSame(eEnd, 'minutes');
  const endsAfterStart = sameMin
    ? eEnd.isAfter(rStart, 'minutes')
    : eEnd.isSameOrAfter(rStart, 'minutes');

  return startsBeforeEnd && endsAfterStart;
};

export const hasEvent = (day, events) => {
  return [...events].some((value) =>
    dateInEventRange({
      event: {
        start: Moment(value.start),
        end: Moment(value.end),
      },
      range: {
        start: day,
        end: day,
      },
    })
  );
};

export const hasEventRange = (day, events) => {
  const ranges = [...events].filter((value) =>
    dateInEventRange({
      event: {
        start: Moment(value.start),
        end: Moment(value.end),
      },
      range: {
        start: day,
        end: day,
      },
    })
  );

  return ranges.some((value) => !value.start.isSame(value.end, 'day'));
};

export const dateFixUnit = (unit?: unitOfTime.Base) => {
  let datePart = unit ? unit.toLowerCase() : unit;
  if (datePart === 'fullyear') {
    datePart = 'year';
  } else if (!datePart) {
    datePart = undefined;
  }
  return datePart as unitOfTime.Base;
};

export const dateAdd = (
  date: IMoment | Date,
  adder: number,
  unit?: unitOfTime.Base
) => {
  const datePart = dateFixUnit(unit);
  return Moment(date).add(adder, datePart).toDate();
};

export const dateStartOf = (date: IMoment, unit?: unitOfTime.Base) => {
  const datePart = dateFixUnit(unit);
  if (datePart) {
    return Moment(date).startOf(datePart).toDate();
  }
  return Moment(date).toDate();
};

export const dateEndOf = (date: IMoment, unit?: unitOfTime.Base) => {
  const datePart = dateFixUnit(unit);
  if (datePart) {
    return Moment(date).endOf(datePart).toDate();
  }
  return Moment(date).toDate();
};

const dateDefineComparators = (
  a,
  b,
  unit?: unitOfTime.Base
): [IMoment, IMoment, unitOfTime.Base] => {
  const datePart = dateFixUnit(unit);
  const dtA = datePart ? Moment(a).startOf(datePart) : Moment(a);
  const dtB = datePart ? Moment(b).startOf(datePart) : Moment(b);
  return [dtA, dtB, datePart];
};

export const dateEqual = (a, b, unit?: unitOfTime.Base) => {
  const [dtA, dtB, datePart] = dateDefineComparators(a, b, unit);
  return dtA.isSame(dtB, datePart);
};

export const dateCeil = (date, unit) => {
  const datePart = dateFixUnit(unit);
  const floor = dateStartOf(date, datePart);

  return dateEqual(floor, date) ? floor : dateAdd(floor, 1, datePart);
};

export const dateDiff = (a, b, unit: unitOfTime.Base = 'day') => {
  const datePart = dateFixUnit(unit);
  const dtA = Moment(a);
  const dtB = Moment(b);
  return dtB.diff(dtA, datePart);
};

export const dateMin = (dateA, dateB) => {
  const dtA = Moment(dateA);
  const dtB = Moment(dateB);
  const minDt = Moment.min(dtA, dtB);
  return minDt.toDate();
};

export const dateMax = (dateA, dateB) => {
  const dtA = Moment(dateA);
  const dtB = Moment(dateB);
  const maxDt = Moment.max(dtA, dtB);
  return maxDt.toDate();
};

export const overlaps = (timeSegments: IMoment[][]) => {
  if (timeSegments.length === 1) {
    return false;
  }

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1[0].diff(timeSegment2[0])
  );

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1];
    const nextStartTime = timeSegments[i + 1][0];

    if (currentEndTime > nextStartTime) {
      return true;
    }
  }

  return false;
};

export type IMoment = moment.Moment;

export default Moment;
