/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  ATMHeader,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
  ATMIcon,
  ATMPopover,
} from 'shared-it-appmod-ui';
import { formatDate, format24hTime } from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import history from 'src/history';
import {
  IRealTimeLogAudit,
  IRealTimeLogAuditAll,
} from 'src/models/real-time-log-audit.model';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import style from '../real-time-log-audit-list/real-time-log-audit-list.module.scss';
import RealTimeLogAuditAllSearch from '../real-time-log-audit-all-search/real-time-log-audit-all-search.component';

type IProps = {
  data: IRealTimeLogAudit[];
  // employees: IEmployeesRealTimeLog[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  today: number;
  currentTab: number;
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IRealTimeLogAuditAll) => void;
  handleClick: () => void;
  // setShowAuditTableAll: (x: boolean) => void;
  showAuditTableAll: boolean;
  handleDownload: () => void;
  setIsAuditSearch: React.Dispatch<React.SetStateAction<boolean>>;
  auditSearchFilters: Partial<IORGDataTableQueryState>;
};

const RealTimeLogAuditAll: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
  handleClick,
  handleSubmit,
  showAuditTableAll,
  // setShowAuditTableAll,
  handleDownload,
  formRef,
  setIsAuditSearch,
  auditSearchFilters,
}) => {
  const [showAuditAll, setShowAuditAll] = useState(true);

  const columns: IORGDataTableColumn<IRealTimeLogAudit>[] = [
    {
      index: 'logId',
      title: Lang.LBL_LOG_ID,
      width: '10%',
    },
    {
      index: 'auditType',
      title: Lang.LBL_AUDIT_TYPE,
      width: '15%',
    },
    {
      index: 'auditEntry',
      title: Lang.LBL_AUDIT_ENTRY,
      sortable: false,
    },
    {
      index: 'updatedAt',
      title: Lang.LBL_AUDIT_TIMESTAMP,
      render: (value) => {
        return (
          <>
            <div className={style.listText}>
              <b>{format24hTime(value)}</b>
              <p>{formatDate(value)}</p>
            </div>
          </>
        );
      },
      width: '15%',
    },
    {
      index: 'emp.lastName',
      title: Lang.LBL_AUDIT_BY,
      sortable: false,
      width: '20%',
      render: (_, value) => {
        return (
          <>
            <div className={style.listText}>
              <b>
                {value && value.emp
                  ? value.emp.firstName && value.emp.lastName
                    ? `${value.emp.lastName}, ${
                        value.emp.firstName
                      } (${formatToRawEmployeeId(value.updatedBy)})`
                    : `Unavailable (${formatToRawEmployeeId(value.updatedBy)})`
                  : `Unavailable (${formatToRawEmployeeId(value.updatedBy)})`}
              </b>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div>
          <RealTimeLogAuditAllSearch
            handleClick={() => {
              handleClick();
              setShowAuditAll(true);
            }}
            handleSubmit={handleSubmit}
            // setShowAuditTableAll={setShowAuditTableAll}
            formRef={formRef}
            setIsAuditSearch={setIsAuditSearch}
            setShowAuditAll={setShowAuditAll}
            showAuditAll={showAuditAll}
            loading={loading}
          />
        </div>
        <ATMPopover
          position="bottom left"
          content="Download"
          on="hover"
          trigger={
            <ATMButton
              secondary
              icon="download"
              floated="right"
              onClick={handleDownload}
              disabled={!data || (data && data.length === 0)}
              loading={loading}
            />
          }
        />
        <br />
        <br />
        {showAuditTableAll ? (
          <div>
            <ORGDataTable
              columns={columns}
              data={data}
              loading={loading}
              celled={false}
              sortable
              showPagination={false}
              location={history.location}
              handleLocation={history.push}
            />
            <span
              className={
                showAuditAll && total > 50 ? style.showBtn : style.hiddenEdit
              }
            >
              <ATMButton
                type="button"
                onClick={() => {
                  handleFetch({
                    ...auditSearchFilters,
                    limit: 0,
                  } as IORGDataTableQueryState);
                  setShowAuditAll(!showAuditAll);
                }}
                content="Show All Entries"
              />
            </span>
          </div>
        ) : (
          <div className={style.searchContainer}>
            <ATMIcon
              circular
              name="search"
              size="large"
              className={style.searchIcon}
            />
            <ATMHeader
              as="h3"
              content={Lang.LBL_VIEW_RECORDS}
              className={style.title}
            />
            <p className={style.subtitle}>{Lang.LBL_SEARCH_ENTRY}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default RealTimeLogAuditAll;
