import { Extension, Editor } from '@tiptap/react';
import { StarterKitOptions } from '@tiptap/starter-kit';

export type IEditor = {
    editor: Editor | undefined;
    extensions: Extension<StarterKitOptions, any>[] | undefined,
    content: string | undefined,
    convertedContent: any | undefined,
};
export default abstract class TEditor {
    private textEditor: IEditor;

    constructor() {
        this.textEditor = {
            content: '',
            editor: undefined as any,
            extensions: [],
            convertedContent: '',
        };
    }

    setEditor(editor: Editor) {
        this.textEditor.editor = editor;
    }

    getEditor() {
        return this.textEditor;
    }

    setExtensions(extensions: Extension<StarterKitOptions, any>[]) {
        this.textEditor.extensions = extensions;
    }

    getExtensions() {
        return this.textEditor.extensions;
    }

    setContent(content: string) {
        this.textEditor.content = content;
    }

    getContent() {
        return this.textEditor.content;
    }

    setConvertedContent(convertedContent: string) {
        this.textEditor.convertedContent = convertedContent;
    }

    getConvertedContent() {
        return this.textEditor.convertedContent;
    }

    abstract prepare(): Promise<void>;
}
