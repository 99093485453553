import { Editor } from '@tiptap/react';
import TEditor, { IEditor } from '.';

export class NoteEditor extends TEditor {
    constructor(private noteEditor: IEditor) {
        super();
      }

      // eslint-disable-next-line @typescript-eslint/require-await
      async prepare(): Promise<void> {
        this.setEditor(this.noteEditor.editor as Editor);
        this.setContent(this.noteEditor.content as string);
        this.setExtensions(this.noteEditor.extensions as any);
      }
}
