/* eslint-disable react/button-has-type */
import { Editor } from '@tiptap/react';
import React from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import { IRealTimeLog } from 'src/models/real-time-log.model';
import styles from './editor.module.scss';

type IProps = {
    data?: IRealTimeLog[];
    editor?: Editor | null,
    withDate?: boolean,
    date?: Date,
    setDate?: React.Dispatch<React.SetStateAction<Date>>;
    isPopup?: boolean;
};

const MenuBar: React.FC<IProps> = ({ editor, isPopup }) => {
    return (
      <>
      <div className={isPopup ? styles.menuBarPopup : styles.menuBar}>
        <ATMButton
          icon="redo"
          onClick={() => {
          return editor?.chain().focus().redo().run();
        }}
          disabled={!editor?.can().chain().focus().undo().run()}
          className={editor?.isActive('redo') ? 'is-active' : ''}
        />
        <ATMButton
          icon="undo"
          onClick={() => {
          return editor?.chain().focus().undo().run();
        }}
          disabled={!editor?.can().chain().focus().undo().run()}
          className={editor?.isActive('undo') ? 'is-active' : ''}
        />
        <ATMButton
          icon="bold"
          onClick={() => {
          return editor?.chain().focus().toggleBold().run();
        }}
          disabled={!editor?.can().chain().focus().toggleBold().run()}
          className={editor?.isActive('bold') ? 'is-active' : ''}
        />
        <ATMButton
          icon="italic"
          onClick={() => {
                return editor?.chain().focus().toggleItalic().run();
            }}
          className={editor?.isActive('italic') ? 'is-active' : ''}
        />
        <ATMButton
          icon="underline"
          onClick={() => {
                return editor?.chain().focus().toggleUnderline().run();
            }}
          className={editor?.isActive('underline') ? 'is-active' : ''}
        />
        <ATMButton
          icon="list ul"
          onClick={() => {
                return editor?.chain().focus().toggleBulletList().run();
                }}
          className={editor?.isActive('bulletList') ? 'is-active' : ''}
        />
        <ATMButton
          icon="numbered list"
          onClick={() => {
          return editor?.chain().focus().toggleOrderedList().run();
          }}
          className={editor?.isActive('orderedList') ? 'is-active' : ''}
        />
      </div>
      </>
    );
  };

  export default MenuBar;
