import { TestContext } from 'yup';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  LERRequestFormStep,
  AuthorizationType,
  NameFlag,
  FacilityType,
  AccessRole,
} from 'src/constants';
import yup from 'src/libraries/validator.library';
import Moment, { overlaps } from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import { FileSchema } from 'src/models/file.model';
import { CrewInfoSchema, CrewInfoSchemaForm } from 'src/models/crew-info.model';
import { NoteSchema } from 'src/models/note.model';
import {
  AuthStatementFormSchema,
  AuthStatementSchema,
  DailyOutageNotesSchema,
} from 'src/models/auth-statement.model';
import { getStartEngineerList } from 'src/helpers/employee.helper';
import { getUser } from 'src/libraries/amplify.library';
import { hasAccess, AccessType, hasRole } from 'src/libraries/access.library';
import { EmployeeInternalSchema } from './employee.model';
import { SubstationSchema } from './substation.model';
import { SortResponseSchema } from './switching.model';
import { VoltSchema } from './volt.model';
import { CancelTypeSchema } from './cancel-type.model';

export const LerRequestOutageFacilityShape = {
  facTypId: yup.number(),
  outgFacId: yup.number().required(),
  outageType: yup
    .object({
      outageTypeDesc: yup.string().nullable(),
      outageTypeId: yup.number().nullable(),
    })
    .nullable(),
  facility: yup
    .object({
      outgFacId: yup.number().nullable(),
      facTypId: yup.number().nullable(),
      outgFacNm: yup.string().nullable(),
      facTyp: yup
        .object({
          facTypId: yup.number().nullable(),
          facTypNm: yup.string().nullable(),
        })
        .nullable(),
      volt: VoltSchema.nullable(),
      spclOpsConcern: yup.string().nullable(),
    })
    .nullable(),
  substationId: yup.string().nullable(),
  substation: SubstationSchema.nullable(),
  voltId: yup.number().nullable(),
  voltage: VoltSchema.nullable(),
  equipmentId: yup.number().nullable(),
  outgTypId: yup.number().required(),
};

export const LerRequestOutageFacilitySchema = yup.object(
  LerRequestOutageFacilityShape
);

export const LerRequestOutageFacilityForm = yup.object({
  facilityType: yup.number().required(),
  substationId: yup
    .string()
    .nullable()
    .when('facilityType', {
      is: (val) => val === FacilityType.Station,
      then: (schema) => schema.required(),
    }),
  facTypId: yup
    .number()
    .nullable()
    .typeError(Lang.MSG_ERROR_REQUIRED)
    .when('facilityType', {
      is: (val) => val === FacilityType.Station,
      then: (schema) => schema.required(),
    }),
  outgFacId: yup.number().required().typeError(Lang.MSG_ERROR_REQUIRED),
  voltId: yup
    .number()
    .nullable()
    .typeError(Lang.MSG_ERROR_REQUIRED)
    .when('facilityType', {
      is: (val) => val === FacilityType.Station,
      then: (schema) => schema.required(),
    }),
  outgTypId: yup.number().required().typeError(Lang.MSG_ERROR_REQUIRED),
  besInd: yup.string().required(),
});

const checkDate = (value, context: TestContext) => {
  const {
    path,
    parent: { startTm, stopTm, allDay, updatedBy },
  } = context;

  const start = Moment(startTm);
  const end = Moment(stopTm);

  if (allDay) {
    return true;
  }

  if (!value) {
    return false;
  }

  if (
    !updatedBy &&
    path.includes('startTm') &&
    start.isBefore(Moment().startOf('day'))
  ) {
    return context.createError({
      message: 'Must be greater than the current date and time',
    });
  }

  if (path.includes('stopTm') && end.isBefore(start)) {
    return context.createError({
      message: 'Must be greater than the start date and time',
    });
  }

  if (start.isValid() && end.isValid()) {
    return start.isBefore(end);
  }

  return true;
};

export const LerRequestOutageDateShape = {
  key: yup.lazy((val) =>
    Array.isArray(val)
      ? yup.array().of(yup.string().defined())
      : yup
          .string()
          .defined()
          .default(() => uuid())
  ),
  actualSwitchOutTm: yup.date().nullable(),
  actualSwitchInTm: yup.date().nullable(),
  actualStopTm: yup.date().nullable(),
  actualStrtTm: yup.date().nullable(),
  significantCostInd: yup.string().nullable(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
  stopTm: yup.date(),
  startTm: yup.date(),
  reqStatId: yup.number(),
  version_no: yup.number(),
  requestId: yup.number(),
  allDay: yup.boolean().default(false),
};

export const LerRequestOutageDateSchema = yup.object(LerRequestOutageDateShape);

export const LerRequestOutageDateFormSchema = yup.object({
  key: yup.lazy((val) =>
    Array.isArray(val)
      ? yup.array().of(yup.string())
      : yup.string().default(() => uuid())
  ),
  startTm: yup
    .date()
    .when('allDay', {
      is: false,
      then: yup.date().required().typeError(Lang.MSG_ERROR_REQUIRED),
    })
    .test('isValid', 'Invalid Start Date', checkDate)
    .typeError(Lang.MSG_ERROR_REQUIRED),
  stopTm: yup
    .date()
    .when('allDay', {
      is: false,
      then: yup.date().required().typeError(Lang.MSG_ERROR_REQUIRED),
    })
    .test('isValid', 'Invalid End Date', checkDate)
    .typeError(Lang.MSG_ERROR_REQUIRED),
  allDay: yup.boolean().default(false),
  reqStatId: yup.number(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
});

export const ShortDescSchema = yup.object({
  descId: yup.number().required(),
  shortDesc: yup.string().required(),
});

export const ShortDescCatSchema = yup.object({
  catId: yup.number().required(),
  shortDescCat: yup.string().required(),
});

export const LerRequestShortDescriptionSchema = yup.object({
  seqNo: yup.number(),
  lerShortDescCatId: yup.number().required(),
  lerShortDescId: yup.number().required(),
  description: ShortDescSchema.clone().nullable(),
  category: ShortDescCatSchema.clone().nullable(),
});

export const LerRequestShortDescriptionFormSchema = yup.object({
  seqNo: yup.number(),
  lerShortDescCatId: yup.number().required(),
  lerShortDescId: yup.number().required(),
});

const LerRequestRadializedSubstationSchema = yup.object({
  version_no: yup.number(),
  substationId: yup.string().required(),
  requestId: yup.number(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

const requiredLine = () => {
  return (value, values) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      from: [_, { value: allValue }],
    } = values;

    if (
      !allValue.outageFacility.substationId &&
      (value === undefined ||
        value === null ||
        (typeof value === 'string' && !value.trim()))
    ) {
      return false;
    }

    return true;
  };
};

const requiredByFacility = (
  outgTypId: AuthorizationType | AuthorizationType[],
  isLine = false
) => {
  const list = !Array.isArray(outgTypId) ? [outgTypId] : outgTypId;

  return (value, values) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      from: [_, { value: allValue }],
    } = values;

    if (
      allValue.outageFacility &&
      (!isLine || isLine ? !allValue.outageFacility.substationId : true) &&
      list.includes(allValue.outageFacility.outgTypId) &&
      value === undefined
    ) {
      return false;
    }

    return true;
  };
};

export const LerRequestJobInformationSchema = yup.object({
  switchOutMin: yup.number().required(),
  switchInMin: yup.number().required(),
  documents: yup.array().of(FileSchema),
  woNbr: yup.string().nullable(),
  jobNotes: yup.string().nullable(),
  jobLoc: yup.string().nullable(),
  wrkDesc: yup.string().nullable(),
  radializedSubs: yup.array().of(LerRequestRadializedSubstationSchema),
  rimsProjectId: yup.string().nullable(),
  rimsProjectPhase: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  clsdGrndDsconctInd: yup.boolean(),
  recalTm: yup.number().nullable(),
  testVoltageApplied: yup.boolean().nullable(),
  splitSaidiCalcInd: yup.boolean().nullable(),
  contingencyPlanInd: yup.boolean().nullable(),
  isolationPoints: yup.string().nullable(),
  inclmntWthrInd: yup.boolean().nullable(),
  phsReqInd: yup.boolean().nullable(),
  affectsRasSpsInd: yup.boolean().nullable(),
  projectId: yup.string().nullable(),
  shortDescriptions: yup.array().of(LerRequestShortDescriptionSchema).defined(),
});

export const LerRequestJobInformationForm = yup.object({
  switchOutMin: yup.number().max(999).notNaN().required(),
  switchInMin: yup.number().max(999).notNaN().required(),
  documents: yup.array().of(FileSchema),
  woNbr: yup.string().nullable(),
  jobNotes: yup.string().nullable(),
  jobLoc: yup
    .string()
    .nullable()
    .max(500)
    .test('required', Lang.MSG_ERROR_REQUIRED, requiredLine()),
  wrkDesc: yup.string().required().max(2500), // 645670 Increase character limit of work description
  radializedSubs: yup.array().of(LerRequestRadializedSubstationSchema),
  rimsProjectId: yup
    .string()
    .nullable()
    .test('required', Lang.MSG_ERROR_REQUIRED, (value) => {
      const empNo = getUser()?.emp_no;

      if (
        hasRole([
          AccessRole.MCC_OUTAGE_REQUESTOR,
          AccessRole.MCC_SUBMITTING_SUPERVISOR,
          AccessRole.MCC_OPERATIONAL_ENGINEER,
        ]) &&
        getStartEngineerList().find((v) => v.empId === empNo) &&
        isEmpty(value)
      ) {
        return false;
      }

      return true;
    }),
  rimsProjectPhase: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  clsdGrndDsconctInd: yup
    .boolean()
    .transform((currentValue) =>
      typeof currentValue !== 'boolean' ? undefined : currentValue
    )
    .test(
      'required',
      Lang.MSG_ERROR_REQUIRED,
      requiredByFacility(AuthorizationType.Hold)
    ),
  recalTm: yup
    .number()
    // .min(1, 'It must be greater than or equal to 1')
    .notNaN()
    .test('required', Lang.MSG_ERROR_REQUIRED, (value, values) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        from: [_, { value: allValue }],
      } = values as any;

      if (
        allValue.outageFacility &&
        allValue.outageFacility.outgTypId !== AuthorizationType.InfoOnly &&
        (value === undefined || value === null)
      ) {
        return false;
      }

      return true;
    }),
  testVoltageApplied: yup
    .boolean()
    .transform((currentValue) =>
      typeof currentValue !== 'boolean' ? undefined : currentValue
    )
    .test(
      'required',
      Lang.MSG_ERROR_REQUIRED,
      requiredByFacility([
        AuthorizationType.Hold,
        AuthorizationType.OkStartProgram,
      ])
    ),
  splitSaidiCalcInd: yup.boolean(),
  contingencyPlanInd: yup
    .boolean()
    .test('required', Lang.MSG_ERROR_REQUIRED, (value, values) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        from: [__, { value: allValue }],
      } = values as any;

      if (
        allValue.jobInformation.recalTm &&
        Number(allValue.jobInformation.recalTm) > 4 &&
        !value
      ) {
        return false;
      }

      return true;
    }),
  isolationPoints: yup.string().nullable(),
  inclmntWthrInd: yup.boolean(),
  phsReqInd: yup.boolean(),
  affectsRasSpsInd: yup.boolean(),
  projectId: yup.string().nullable(),
  shortDescriptions: yup.array().of(LerRequestShortDescriptionFormSchema),
});

export const OutageEmsDataSchema = yup.object({
  requestId: yup.number(),
  version_no: yup.string(),
  emsId: yup.string().required(),
  top: yup.string().required(),
  station: yup.string().required(),
  busName: yup.string().required(),
  equipmentName: yup.string().required(),
  equipmentType: yup.number().required().notNaN(),
  position: yup.boolean().required(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  facTyp: yup
    .object({
      facTypId: yup.number().nullable(),
      facTypNm: yup.string().nullable(),
    })
    .nullable(),
});

export const OutageEquipRatingSchema = yup.object({
  requestId: yup.number(),
  version_no: yup.string(),
  equipmentId: yup.number().required(),
  normalRating: yup.string().nullable(),
  emergencyRating: yup.string().nullable(),
  highEmergencyRating: yup.string().nullable(),
  startTime: yup.date().required(),
  endTime: yup.date().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const OutageSwitchIdSchema = yup.object({
  requestId: yup.number(),
  version_no: yup.string(),
  switchId: yup.string(),
  defaultPosInd: yup.boolean(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const OutageCaisoIdSchema = yup.object({
  requestId: yup.number(),
  version_no: yup.string(),
  caisoId: yup.string(),
  facilityModelInd: yup.boolean(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const LerRequestISOSchema = yup.object({
  emsDatas: yup.array().of(OutageEmsDataSchema).nullable(),
  equipRatings: yup.array().of(OutageEquipRatingSchema).nullable(),
  switchings: yup.array().of(OutageSwitchIdSchema).nullable(),
  caisoTrs: yup.array().of(OutageCaisoIdSchema).nullable(),
  isoWorkKindId: yup.string().nullable(),
  primIsoCauseCode: yup.string().nullable(),
  secondIsoCauseCode: yup.string().nullable(),
});

export const LerRequestISOFormSchema = yup.object({
  emsDatas: yup.array().of(OutageEmsDataSchema).nullable(),
  equipRatings: yup.array().of(OutageEquipRatingSchema).nullable(),
  switchings: yup.array().of(OutageSwitchIdSchema).nullable(),
  caisoTrs: yup.array().of(OutageCaisoIdSchema).nullable(),
  isoWorkKindId: yup.string().required().typeError(Lang.MSG_ERROR_REQUIRED),
  primIsoCauseCode: yup.string().nullable(),
  // .test('isRequired', Lang.MSG_ERROR_REQUIRED, (value, values) => {
  //   const {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     from: [__, { value: allValue }],
  //   } = values as any;

  //   if (allValue.isoTrs.secondIsoCauseCode && !value) {
  //     return false;
  //   }

  //   return true;
  // }),
  secondIsoCauseCode: yup.string().nullable(),
  // .when('primIsoCauseCode', {
  //   is: (val) => val,
  //   then: (schema) => schema.required(),
  // }),
});

export const SendCaisoShape = {
  submitStatus: yup.string(),
  OutageResult: yup
    .array(
      yup.object({
        mRID: yup.string(),
        description: yup.string(),
        type: yup.string(),
      })
    )
    .ensure(),
};

const LerRequestCostSchema = yup.object({
  significantCostTasks: yup.array().of(
    yup.object({
      significantCostId: yup.number(),
      email: yup.boolean(),
      save: yup.boolean(),
    })
  ),
  significantCostNote: NoteSchema.optionalContent(),
});

export const EmailGroupShape = {
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  groupId: yup.number().required(),
  emailType: yup.string().required(),
  status: yup.string().nullable(),
  statusUpdatedBy: yup.string().nullable(),
  statusUpdatedOn: yup.date().nullable(),
  comments: yup.string().nullable(),
  emailSentAt: yup.date().nullable(),
  emailResentAt: yup.date().nullable(),
  emailBody: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const EmailStatusMessageShape = {
  failedOutageEmail: yup
    .array()
    .of(
      yup.object({
        status: yup.boolean().nullable(),
        type: yup.string().nullable(),
        message: yup.string().nullable(),
      })
    )
    .nullable(),
  successOutageEmail: yup
    .array()
    .of(
      yup.object({
        status: yup.boolean().nullable(),
        type: yup.string().nullable(),
        message: yup.string().nullable(),
      })
    )
    .nullable(),
};

export const LerRequestSystemSchema = yup.object({
  sysChngInd: yup.boolean().nullable(),
  sysChngSummaryId: yup.number().nullable(),
  sysChngSummary: NoteSchema.optionalContent(),
});

export const UpdatedByEmployeeSchema = yup.object({
  empId: yup.string().nullable(),
  firstName: yup.string().nullable(),
  fullName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  middleName: yup.string().nullable(),
});

export const LerRequestOutageReject = yup.object({
  rejectId: yup.number().nullable(),
  requestId: yup.number().nullable(),
  rejectionReason: yup.string().nullable(),
  rejectorId: yup.string().nullable(),
  rejectionDate: yup.string().nullable(),
  rejectionType: yup.string().nullable(),
  rejector: UpdatedByEmployeeSchema.clone().nullable(),
});

export const LerRequestShape = {
  requestId: yup.number().required(),
  version_no: yup.number(),
  requestStat: yup.number(),
  changeReqStat: yup.string().nullable(),
  requestorName: yup.string(),
  facilityName: yup.string(),
  substation: yup.string(),
  startDate: yup.date(),
  endDate: yup.date(),
  days: yup.number(),
  outgCategNm: yup.string(),
  schedulerAssignId: yup.string().nullable(),
  schedulerAssign: EmployeeInternalSchema.clone().nullable(),
  updatedByEmployee: UpdatedByEmployeeSchema.clone().nullable(),
  cancelor: UpdatedByEmployeeSchema.clone().nullable(),
  outageReject: LerRequestOutageReject.nullable(),
  rejectNotes: yup
    .array()
    .of(
      yup
        .object({
          rejectId: yup.number().nullable(),
          requestId: yup.number().nullable(),
          rejectionReason: yup.string().nullable(),
          rejectorId: yup.string().nullable(),
          version_no: yup.string().nullable(),
          rejectionDate: yup.date().nullable(),
          updatedAt: yup.date().nullable(),
          updatedBy: yup.string().nullable(),
          rejectionType: yup.string().nullable(),
          rejector: UpdatedByEmployeeSchema.clone().nullable(),
        })
        .nullable()
    )
    .nullable(),
  cancelType: CancelTypeSchema.nullable(),
  cnclAt: yup.date().nullable(),
  cnclNoteId: yup.number().nullable(),
  outageType: yup.object({
    outageTypeDesc: yup.string(),
    outageTypeId: yup.number(),
  }),
  outageEmail: yup.object(EmailStatusMessageShape),
  grpId: yup.number().nullable(),
  outageFacility: LerRequestOutageFacilitySchema.required(),
  outageDates: yup.array().of(LerRequestOutageDateSchema.clone()).required(),
  jobInformation: LerRequestJobInformationSchema.required(),
  crewInformation: CrewInfoSchema.required(),
  authorizationStatement: AuthStatementSchema.required(),
  isoTrs: LerRequestISOSchema.required(),
  significantCost: LerRequestCostSchema.nullable(),
  sortResponses: yup.array().of(SortResponseSchema),
  system: LerRequestSystemSchema.nullable(),
  requestorId: yup.string().nullable(),
  supvId: yup.string().nullable(),
  firstSubDate: yup.date().nullable(),
  lastSubDate: yup.date().nullable(),
  lastAccDate: yup.date().nullable(),
  pendingCaiso: yup.string().nullable(),
  // US #605811 10/02/2023 Date Modified on Version History
  updatedAt: yup
    .string()
    .transform((value) => Moment(value).format('MM/DD/YYYY HH:mm:ss'))
    .nullable(),
  // updateNote: NoteSchema.nullable(),
  completedOn: yup.date().nullable(),
  completionSetBy: yup.string().nullable(),
  isComplete: yup.boolean().nullable(),
};

export const LerRequestSortResponseForm = yup.object({
  sortResponses: yup.array().of(SortResponseSchema),
});

export const LerRequestPrintFormSchema = yup.object({
  outageDate: yup.date().required(),
});

export const LerRequestFormSchema = yup.object({
  step: yup.string(),
  requestStat: yup.number().nullable(),
  crStatus: yup.string().nullable(),
  outageFacility: LerRequestOutageFacilityForm.nullable()
    .optionalContent(true, null)
    .when('step', {
      is: (value) => !value || value === LERRequestFormStep.OUTAGE_FACILITY,
      then: LerRequestOutageFacilityForm.optionalContent(false, null),
    }),
  outageDates: yup
    .array()
    .optional()
    .when('step', {
      is: (value) => !value || value === LERRequestFormStep.OUTAGE_DATE,
      then: yup
        .array()
        .of(LerRequestOutageDateFormSchema)
        .min(1, Lang.MSG_LER_REQUEST_FORM_OUTAGE_DATE_ERROR)
        .required(),
    }),
  jobInformation: LerRequestJobInformationForm.nullable()
    .optionalContent(true, null)
    .when('step', {
      is: (value) => !value || value === LERRequestFormStep.JOB_INFORMATION,
      then: LerRequestJobInformationForm.optionalContent(false, null),
    }),
  crewInformation: CrewInfoSchemaForm.nullable()
    .optionalContent(true, null)
    .when('step', {
      is: (value) => !value || value === LERRequestFormStep.CREW_INFORMATION,
      then: CrewInfoSchemaForm.optionalContent(false, null),
    }),
  authorizationStatement: AuthStatementFormSchema.nullable()
    .optionalContent(true, null)
    .when('step', {
      is: (value) =>
        (!value || value === LERRequestFormStep.AUTHORIZATION_STATEMENT) &&
        hasAccess(AccessType.OUTAGE_REQUEST_AUTH_STATEMENT),
      then: AuthStatementFormSchema.optionalContent(false, null),
    }),
  isoTrs: LerRequestISOFormSchema.nullable()
    .optionalContent(true, null)
    .when('step', {
      is: (value) =>
        (!value || value === LERRequestFormStep.ISO) &&
        hasAccess(AccessType.OUTAGE_REQUEST_ISO),
      then: LerRequestISOFormSchema.optionalContent(false, null),
    }),
  significantCost: LerRequestCostSchema.nullable()
    .optionalContent(true, null)
    .when('step', {
      is: (value) =>
        (!value || value === LERRequestFormStep.COST) &&
        hasAccess(AccessType.OUTAGE_REQUEST_COST),
      then: LerRequestCostSchema.required(),
    }),
  sortResponses: yup.array().of(SortResponseSchema).nullable(),
  system: LerRequestSystemSchema.nullable(),
  pendingCaiso: yup.string().nullable(),
  // updateReason: yup.string().nullable().max(1000),
});

export const LandlinePhoneShape = {
  areaCode: yup.string().defined(),
  localNumber: yup.string().defined(),
};

export const PersonShape = {
  firstName: yup.string().defined(),
  lastName: yup.string().defined(),
  landlinePhone: yup.object(LandlinePhoneShape).defined(),
};

export const PeriodShape = {
  end: yup.date().defined(),
  start: yup.date().defined(),
};

export const NameTypeShape = {
  name: yup.string().defined(),
};

export const NameShape = {
  name: yup.string().defined(),
  NameType: yup.object(NameTypeShape).defined(),
};

export const SwitchingOperationShape = {
  newState: yup.string().defined(),
  newStatePeriod: yup.object(PeriodShape).defined(),
};

export const SwitchShape = {
  mRID: yup.string(),
  Names: yup.array().of(yup.object(NameShape).defined()).defined(),
  SwitchingOperations: yup
    .array()
    .of(yup.object(SwitchingOperationShape).defined())
    .defined(),
};

export const OutagedEquipmentShape = {
  switchModelingComplete: yup.string().defined(),
  useFacilityOutageDefinition: yup.string().defined(),
  Switch: yup.array().of(yup.object(SwitchShape).defined()),
};

export const EquipmentShape = {
  Names: yup.array().of(yup.object(NameShape).defined()).defined(),
  OutagedEquipment: yup.object(OutagedEquipmentShape).defined(),
};

export const OperatingParticipantShape = {
  mRID: yup.string().defined(),
  Person: yup.array().of(yup.object(PersonShape).defined()).defined(),
};

export const OutageCauseShape = {
  code: yup.string().defined(),
};

export const WorkShape = {
  workKind: yup.string().required('Nature of Work is required'),
};

export const AdjustedOperationalRatingShape = {
  activePeriod: yup.object({
    end: yup.date(),
    start: yup.date(),
  }),
  normalRestrictedValue: yup.object({
    multiplier: yup.string(),
    unit: yup.string(),
    value: yup.number(),
  }),
  isRecurring: yup.string(),
  emergencyRestrictedValue: yup.object({
    multiplier: yup.string(),
    unit: yup.string(),
    value: yup.number(),
  }),
};

export const TransmissionOutageSubmitShape = {
  description: yup.string().defined(),
  comment: yup.string().defined(),
  discoveryDateTime: yup.date(),
  emergencyReturnTime: yup.number().defined(),
  emergencyReturnTimeType: yup.string().defined(),
  mktOrgOutageID: yup.string().defined(),
  outageEMSICCP: yup.boolean(),
  outOfServiceRASSPS: yup.boolean(),
  protectionZone: yup.boolean(),
  redundancyRASSPS: yup.boolean(),
  RIMSProjectID: yup.string(),
  RIMSProjectPhase: yup.string(),
  Equipments: yup.array().of(yup.object(EquipmentShape).defined()).defined(),
  estimatedPeriod: yup.object(PeriodShape).defined(),
  OperatingParticipant: yup.object(OperatingParticipantShape).defined(),
  OutageCause: yup.array().of(yup.object(OutageCauseShape).defined()),
  Work: yup.object(WorkShape).defined(),
};

export const TransmissionOutageChangeShape = {
  mRID: yup.string().defined(),
  description: yup.string().defined(),
  comment: yup.string().defined(),
  confirmedFlagBATOP: yup.boolean(),
  emergencyFlag: yup.boolean(),
  emergencyReturnTime: yup.number().defined(),
  emergencyReturnTimeType: yup.string().defined(),
  mktOrgOutageID: yup.string().defined(),
  operationalFlag: yup.boolean().defined(),
  outageCoordinationLongTermPlanning: yup.string().defined(),
  outageEMSICCP: yup.boolean(),
  outOfServiceRASSPS: yup.boolean(),
  protectionZone: yup.boolean(),
  redundancyRASSPS: yup.boolean(),
  RIMSProjectCommissionDate: yup.date(),
  RIMSProjectCompletion: yup.string(),
  RIMSProjectID: yup.string().defined(),
  RIMSProjectPhase: yup.string().defined(),
  actualPeriod: yup.object({
    end: yup.date(),
    start: yup.date(),
  }),
  Equipments: yup
    .array()
    .of(
      yup.object({
        mRID: yup.string().defined(),
        Names: yup
          .array()
          .of(yup.object(NameShape).defined())
          .defined()
          .compact((item) => {
            return item.NameType.name === NameFlag.PARTICIPANTNAME;
          }),
        AdjustedOperationalRating: yup.array(
          yup.object(AdjustedOperationalRatingShape)
        ),
        OutagedEquipment: yup
          .object({
            ...OutagedEquipmentShape,
            Switch: yup.array().of(
              yup
                .object({
                  ...SwitchShape,
                  Names: yup
                    .array()
                    .of(yup.object(NameShape).defined())
                    .defined()
                    .compact((item) => {
                      return item.NameType.name === NameFlag.PARTICIPANTNAME;
                    }),
                })
                .defined()
            ),
          })
          .defined(),
        VoltageLevel: yup.object({
          BaseVoltage: yup.object({
            nominalVoltage: yup.object({
              multiplier: yup.string(),
              unit: yup.string(),
              value: yup.number(),
            }),
          }),
        }),
      })
    )
    .defined(),
  estimatedPeriod: yup.object(PeriodShape).defined(),
  OperatingParticipant: yup.object(OperatingParticipantShape).defined(),
  OutageCause: yup.array().of(yup.object(OutageCauseShape).defined()),
  OutageChangeRequest: yup
    .object({
      action: yup.string().defined(),
      actionSubType: yup.string(),
    })
    .defined(),
  Work: yup.object(WorkShape).defined(),
};

export const TransmissionOutageShape = {
  ...TransmissionOutageSubmitShape,
  createdBy: yup.string().defined(),
  createdDateTime: yup.date().defined(),
  lastModifiedDateTime: yup.date().defined(),
  lastUpdatedBy: yup.string().defined(),
  affectsRASSPS: yup.string(),
  confirmedFlagBATOP: yup.boolean().defined(),
  emergencyFlag: yup.boolean().defined(),
  isFinalApprovalReq: yup.string().defined(),
  operationalFlag: yup.boolean().defined(),
  outageCoordinationLongTermPlanning: yup.string().defined(),
  outageStatus: yup.string().defined(),
  outageStatusReason: yup.string().defined(),
  outageType: yup.string().defined(),
  RCOutageType: yup.string(),
  RCPriorityDate: yup.date(),
  RIMSProjectCommissionDate: yup.date(),
  RIMSProjectCompletion: yup.string().defined(),
  versionID: yup.number().defined(),
  actualPeriod: yup.object({
    ...PeriodShape,
    start: yup.date(),
    end: yup.date(),
  }),
  Equipments: yup
    .array()
    .of(
      yup
        .object({
          ...EquipmentShape,
          mRID: yup.string().defined(),
          name: yup.string().defined(),
          PSRType: yup
            .object({
              type: yup.string().defined(),
            })
            .defined(),
          OutagedEquipment: yup
            .object({
              ...OutagedEquipmentShape,
              Switch: yup
                .array()
                .of(
                  yup.object({
                    ...SwitchShape,
                    SwitchingOperations: yup
                      .array()
                      .of(yup.object(SwitchingOperationShape).defined())
                      .ensure(),
                    mRID: yup.string().defined(),
                    name: yup.string().defined(),
                  })
                )
                .ensure(),
            })
            .defined(),
          AdjustedOperationalRating: yup
            .array()
            .of(yup.object(AdjustedOperationalRatingShape).defined())
            .ensure(),
          VoltageLevel: yup
            .object({
              BaseVoltage: yup
                .object({
                  nominalVoltage: yup
                    .object({
                      multiplier: yup.string().defined(),
                      unit: yup.string().defined(),
                      value: yup.number().defined(),
                    })
                    .defined(),
                })
                .defined(),
            })
            .defined(),
        })
        .defined()
    )
    .ensure()
    .defined(),
  mRID: yup.string().defined(),
  OperatingParticipant: yup
    .object({
      ...OperatingParticipantShape,
      Person: yup
        .array()
        .of(
          yup.object({
            firstName: yup.string(),
            lastName: yup.string(),
            landlinePhone: yup.object({
              areaCode: yup.string(),
              localNumber: yup.string(),
            }),
          })
        )
        .ensure(),
    })
    .defined(),
  OutageChangeRequest: yup
    .object({
      mRID: yup.string().defined(),
      action: yup.string().defined(),
      actionReason: yup.string(),
      actionSubType: yup.string(),
      status: yup.string(),
      statusReason: yup.string(),
    })
    .defined(),
  OutageValidation: yup.object({
    OutageResult: yup
      .array(
        yup.object({
          mRID: yup.string(),
          description: yup.string(),
          type: yup.string(),
        })
      )
      .ensure(),
  }),
  congestionRevenueRightsDesignation: yup.string().defined(),
  isNewEquipmentEnergized: yup.string().defined(),
  trumpFlag: yup.string().defined(),
};

export const LerRequestSchema = yup.object(LerRequestShape);
export const EmailGroupSchema = yup.object(EmailGroupShape);
export const EmailGroupMultiplePayloadSchema = yup
  .array()
  .of(EmailGroupSchema)
  .required();

export const LerRequestVersionListSchema = yup.object({
  rows: yup.array().of(LerRequestSchema),
  count: yup.number().required(),
});

export const LerRequestVersionSchema = LerRequestSchema.clone();

export const LerRequestOutageFacilityListForm = yup.object({
  outageDatelist: yup
    .array()
    .of(LerRequestOutageDateFormSchema.clone())
    .defined()
    .test('overlap', Lang.MSG_OUTAGE_DATE_ERROR, (values) => {
      if (values) {
        return !overlaps(
          values.map((v) => [Moment(v.startTm), Moment(v.stopTm)])
        );
      }

      return true;
    }),
});

export const LerRequestItemSchema = yup.object({
  days: yup.number(),
  cancelNote: NoteSchema.nullable(),
  cancelType: CancelTypeSchema.nullable(),
  cnclAt: yup.date().nullable(),
  cancelor: UpdatedByEmployeeSchema.clone().nullable(),
  schedulerAssign: EmployeeInternalSchema.clone().nullable(),
  crew: EmployeeInternalSchema.clone().nullable(),
  requestor: EmployeeInternalSchema.clone().nullable(),
  supervisor: EmployeeInternalSchema.clone().nullable(),
  shortDescriptions: yup.array().of(LerRequestShortDescriptionSchema).defined(),
  outageFacility: LerRequestOutageFacilitySchema.required(),
  outageDates: yup.array().of(LerRequestOutageDateSchema.clone()).required(),
  outageEmsIccpInd: yup.string().nullable(),
  protectionZoneInd: yup.string().nullable(),
  redundancyRasSpsInd: yup.string().nullable(),
  outOfServiceRasSpsInd: yup.string().nullable(),
  omsUseFacilityModelInd: yup.string().nullable(),
  wrkDesc: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  isoWorkKindId: yup.string().nullable(),
  cnclNoteId: yup.number().nullable(),
  woNbr: yup.string().nullable(),
  schedulerAssignId: yup.string().nullable(),
  crewId: yup.string().nullable(),
  requestorId: yup.string().nullable(),
  changeReqStat: yup.string().nullable(),
  requestStat: yup.number().nullable(),
  requestId: yup.number().required(),
  version_no: yup.number().required(),
  jobLoc: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  clsdGrndDsconctInd: yup.boolean(),
  testVoltageApplied: yup.boolean().nullable(),
  inclmntWthrInd: yup.boolean().nullable(),
  fldckBy: yup.string().nullable(),
  crewCellNbr: yup.string().nullable(),
  genlFormnCtrctAdmin: yup.string().nullable(),
  jobNotes: yup.string().nullable(),
  dailyNotes: yup.array().of(DailyOutageNotesSchema.clone()).nullable(),
  isolationPts: yup.string().nullable(),
  grpId: yup.number().nullable(),
  supvId: yup.string().nullable(),
  emailGroups: yup.array().of(EmailGroupSchema),
  outageReject: LerRequestOutageReject.nullable(),
  sortOrderNumber: yup.string().nullable(),
});

export const LerRequestRelatedLerItemSchema = yup.object({
  requestId: yup.number().nullable(),
  requestStat: yup.number().nullable(),
  changeReqStat: yup.string().nullable(),
  requestorId: yup.string().nullable(),
  crewId: yup.string().nullable(),
  schedulerAssignId: yup.string().nullable(),
  supvId: yup.string().nullable(),
  woNbr: yup.string().nullable(),
  cnclNoteId: yup.number().nullable(),
  isoWorkKindId: yup.string().nullable(),
  primIsoCauseCode: yup.string().nullable(),
  secondIsoCauseCode: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  wrkDesc: yup.string().nullable(),
  omsUseFacilityModelInd: yup.string().nullable(),
  outOfServiceRasSpsInd: yup.string().nullable(),
  redundancyRasSpsInd: yup.string().nullable(),
  protectionZoneInd: yup.string().nullable(),
  outageEmsIccpInd: yup.string().nullable(),
  switchOutMin: yup.number().nullable(),
  switchInMin: yup.number().nullable(),
  grpId: yup.number().nullable(),
  outageFacility: yup
    .object({
      outgFacId: yup.number().nullable(),
      substationId: yup.string().nullable(),
      outgTypId: yup.number().nullable(),
      voltId: yup.number().nullable(),
      facility: yup
        .object({
          outgFacNm: yup.string().nullable(),
          outgFacId: yup.number().nullable(),
          facTypId: yup.number().nullable(),
          voltId: yup.number().nullable(),
          facTyp: yup
            .object({
              facTypId: yup.number().nullable(),
              facTypNm: yup.string().nullable(),
            })
            .nullable(),
          volt: yup
            .object({
              voltId: yup.number().nullable(),
              voltNm: yup.string().nullable(),
            })
            .nullable(),
        })
        .nullable(),
      substation: yup
        .object({
          substationId: yup.string().nullable(),
          name: yup.string().nullable(),
        })
        .nullable(),
      outageType: yup
        .object({
          outageTypeId: yup.number().nullable(),
          outageTypeDesc: yup.string().nullable(),
        })
        .nullable(),
      voltage: yup
        .object({
          voltId: yup.number().nullable(),
          voltNm: yup.string().nullable(),
        })
        .nullable(),
    })
    .nullable(),
  outageDates: yup
    .array()
    .of(
      yup.object({
        requestId: yup.number().nullable(),
        version_no: yup.string().nullable(),
        reqStatId: yup.number(),
        startTm: yup.date().nullable(),
        stopTm: yup.date().nullable(),
        updatedAt: yup.date().nullable(),
        updatedBy: yup.string().nullable(),
        significantCostInd: yup.string().nullable(),
        actualStrtTm: yup.date().nullable(),
        actualStopTm: yup.date().nullable(),
        actualSwitchInTm: yup.date().nullable(),
        actualSwitchOutTm: yup.date().nullable(),
      })
    )
    .nullable(), // yup.array(yup.object(OutageDateShape).required()).required(),
});

export const LerRequestRelatedLerListPayloadSchema = yup.object({
  count: yup.number().nullable(),
  rows: yup.array().of(LerRequestRelatedLerItemSchema.clone()),
});

export const LerRequestItemSearchListPayloadShape = yup.object({
  days: yup.number(),
  cancelNote: NoteSchema.nullable(),
  cancelType: CancelTypeSchema.nullable(),
  cnclAt: yup.date().nullable(),
  cancelor: UpdatedByEmployeeSchema.clone().nullable(),
  schedulerAssign: EmployeeInternalSchema.clone().nullable(),
  crew: EmployeeInternalSchema.clone().nullable(),
  requestor: EmployeeInternalSchema.clone().nullable(),
  shortDescriptions: yup.array().of(LerRequestShortDescriptionSchema).defined(),
  outageFacility: LerRequestOutageFacilitySchema.required(),
  outageDates: yup.array().of(LerRequestOutageDateSchema.clone()).required(),
  outageEmsIccpInd: yup.string().nullable(),
  protectionZoneInd: yup.string().nullable(),
  redundancyRasSpsInd: yup.string().nullable(),
  outOfServiceRasSpsInd: yup.string().nullable(),
  omsUseFacilityModelInd: yup.string().nullable(),
  wrkDesc: yup.string().nullable(),
  recalTm: yup.number().nullable(),
  isoWorkKindId: yup.string().nullable(),
  cnclNoteId: yup.number().nullable(),
  woNbr: yup.string().nullable(),
  schedulerAssignId: yup.string().nullable(),
  crewId: yup.string().nullable(),
  requestorId: yup.string().nullable(),
  changeReqStat: yup.string().nullable(),
  requestStat: yup.number().nullable(),
  requestId: yup.number().required(),
  version_no: yup.number().required(),
  jobLoc: yup.string().nullable(),
  facTbsCoord: yup.string().nullable(),
  clsdGrndDsconctInd: yup.boolean(),
  testVoltageApplied: yup.boolean().nullable(),
  inclmntWthrInd: yup.boolean().nullable(),
  fldckBy: yup.string().nullable(),
  crewCellNbr: yup.string().nullable(),
  genlFormnCtrctAdmin: yup.string().nullable(),
  jobNotes: yup.string().nullable(),
  dailyNotes: yup.array().of(DailyOutageNotesSchema.clone()).nullable(),
  isolationPts: yup.string().nullable(),
  grpId: yup.number().nullable(),
  supvId: yup.string().nullable(),
  emailGroups: yup.array().of(EmailGroupSchema),
  outageReject: LerRequestOutageReject.nullable(),
  outgCategNm: yup.string().nullable(),
  rimsProjectId: yup.string().nullable(),
  rimsProjectPhase: yup.string().nullable(),
});

export const LerRequestItemSearchListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(LerRequestItemSearchListPayloadShape.clone()),
});

export const LerRequestItemListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(LerRequestItemSchema.clone()),
});

export const LerRequestAssignToSchema = yup.object({
  empId: yup.string(),
  requestId: yup.number(),
});

export const LerRequestListPayloadSchema = yup.object({
  count: yup
    .object({
      open: yup.number(),
      all: yup.number(),
    })
    .nullable(),
  rows: yup.array().of(LerRequestSchema.clone()),
});

export const LerRequestPayloadSchema = LerRequestSchema.clone();

export const LerRequestDraftSchema = yup.object({
  outageRequestDraftId: yup.number().required(),
  content: yup.mixed(),
  updatedBy: yup.string(),
  updatedAt: yup.date(),
});

export const LerRequestCountSchema = yup.object({
  newTotal: yup.number().default(0),
  changeTotal: yup.number().default(0),
  draftTotal: yup.number().default(0),
  pendingCaisoTotal: yup.number().default(0),
});

export const LerRequestDraftListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(LerRequestDraftSchema.clone()),
});

export const LerRequestDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const LerRequestSingleEmailSchema = yup.object({
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  emailBody: yup.string(),
  groupId: yup.number().required(),
  emailType: yup.string().required(),
  userGroup: yup.object({
    userGrpEmail: yup.string().nullable(),
    userGroupMembers: yup
      .array()
      .of(
        yup.object({
          empId: yup.string().required(),
          emailAdd: yup.string().required(),
        })
      )
      .required(),
  }),
});

export const LerRequestEmailNotificationSchema = yup.object({
  requestId: yup.number().required(),
  version_no: yup.number().required(),
  emailBody: yup.string().nullable(),
  emailGroups: yup
    .array()
    .of(
      yup
        .object({
          groupId: yup.number(),
          grpNm: yup.string().nullable(),
          emailType: yup.string().required(),
          userGroup: yup
            .object({
              userGrpEmail: yup.string().nullable(),
              userGroupMembers: yup
                .array()
                .of(
                  yup.object({
                    empId: yup.string().required(),
                    emailAdd: yup.string().nullable().when('userGrpEmail', {
                      is: null,
                      then: yup.string().required(),
                    }),
                  })
                )
                .required(),
            })
            .nullable(),
          isSend: yup.boolean().required().default(false),
        })
        .required()
    )
    .required(),
});

export const LerRequestCancelFormSchema = yup.object({
  cancelTypeId: yup.number().required().notNaN(),
  cancelNote: yup.string().required().max(1000),
});

export const LerRequestRejectFormSchema = yup.object({
  rejectionReason: yup.string().required().max(1000),
});

export const LerRequestEmailApproveRejectSchema = yup.object({
  requestId: yup.number().required(),
  version_no: yup.string().required(),
  groupId: yup.number().required(),
  status: yup.string().required(),
  comments: yup.string().nullable(),
});

export const LerRequestEtsFormDispatchSchema = yup.object({
  isChecked: yup.boolean().required().default(false),
  out: yup.object({
    byAt: yup.string(),
    sortTime: yup
      .date()
      .transform((currentValue) =>
        Moment(currentValue).isValid() ? currentValue : undefined
      )
      .typeError('This field is required'),
  }),
  back: yup.object({
    byAt: yup.string(),
    sortTime: yup
      .date()
      .transform((currentValue) =>
        Moment(currentValue).isValid() ? currentValue : undefined
      )
      .typeError('This field is required'),
  }),
  etmNbr: yup.number().required(),
  tlSub: yup
    .string()
    .nullable()
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.string().required(),
    }),
  subPole: yup
    .string()
    .nullable()
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.string().required(),
    }),
  outgFacId: yup.number().nullable(),
  switchId: yup.string().nullable(),
  substationId: yup.string().nullable(),
  poleId: yup.string().nullable(),
  subDistId: yup
    .string()
    .nullable()
    .when('isChecked', {
      is: (value) => Boolean(value) === true,
      then: yup.string().required(),
    }),
});

export const LerRequestEtsFormDispatchFormSchema = yup.object({
  etsDispatch: yup.array().of(LerRequestEtsFormDispatchSchema).required(),
});

export const SendCaisoErrorSchema = yup.object(SendCaisoShape).nullable();
export const SendCaisoSchema = yup
  .array()
  .of(yup.object(SendCaisoShape).nullable());
export const TransmissionOutageSchema = yup.object(TransmissionOutageShape);
export const TransmissionOutageListPayloadSchema = yup
  .array()
  .of(yup.object(TransmissionOutageShape));
export const PeriodSchema = yup.object(PeriodShape);
export const EmailStatusMessageSchema = yup.object(EmailStatusMessageShape);
export const SystemChangeTasksEmailSchema = yup.object({
  requestId: yup.number().required(),
  previousSystemChangeTasksInd: yup.boolean().required(),
});

export const LerRequestEmployeeSchema = yup.object({
  lastName: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  fullName: yup.string().nullable(),
  email: yup.string().nullable(),
  empId: yup.string().required(),
  type: yup.string().required(),
});

export const LerRequestEmployeeListPayloadSchema = yup
  .array()
  .of(LerRequestEmployeeSchema.clone());

export const QueryMessageCountSchema = yup.object({
  transmissionOutage: yup.object({
    basicQueueMsgCount: yup.number().required(),
    deadQueueMsgCount: yup.number().required(),
  }),
  switchingRequest: yup.object({
    basicQueueMsgCount: yup.number().required(),
    deadQueueMsgCount: yup.number().required(),
  }),
});

export type ICaiso = yup.Asserts<typeof SendCaisoSchema>;
export type ILerRequest = yup.Asserts<typeof LerRequestSchema>;
export type ILerRequestForm = yup.Asserts<typeof LerRequestFormSchema>;
export type ILerRequestPrintForm = yup.Asserts<
  typeof LerRequestPrintFormSchema
>;
export type ILerRequestOutageFacility = yup.Asserts<
  typeof LerRequestOutageFacilitySchema
>;
export type ILerRequestOutageFacilityForm = yup.Asserts<
  typeof LerRequestOutageFacilityForm
>;
export type ILerRequestOutageDate = yup.Asserts<
  typeof LerRequestOutageDateSchema
>;
export type ILerRequestOutageDateForm = yup.Asserts<
  typeof LerRequestOutageDateFormSchema
>;
export type ILerRequestOutageFacilityListForm = yup.Asserts<
  typeof LerRequestOutageFacilityListForm
>;
export type ILerRequestJobInformation = yup.Asserts<
  typeof LerRequestJobInformationSchema
>;
export type ILerRequestJobInformationForm = yup.Asserts<
  typeof LerRequestJobInformationForm
>;
export type ILerRequestShortDescriptionFrom = yup.Asserts<
  typeof LerRequestShortDescriptionSchema
>;
export type ILerRequestEmailNotification = yup.Asserts<
  typeof LerRequestEmailNotificationSchema
>;
export type ILerRequestSingleEmail = yup.Asserts<
  typeof LerRequestSingleEmailSchema
>;

export type ILerRequestApproveRejectEmail = yup.Asserts<
  typeof LerRequestEmailApproveRejectSchema
>;

export type ILerRequestDraft = yup.Asserts<typeof LerRequestDraftSchema>;

export type ILerRequestVersion = yup.Asserts<typeof LerRequestVersionSchema>;
export type ILerRequestVersionList = yup.Asserts<
  typeof LerRequestVersionListSchema
>;
export type ILerRequestISO = yup.Asserts<typeof LerRequestISOSchema>;

export type IOutageEmsData = yup.Asserts<typeof OutageEmsDataSchema>;
export type IOutageEquipRating = yup.Asserts<typeof OutageEquipRatingSchema>;
export type IOutageSwitchId = yup.Asserts<typeof OutageSwitchIdSchema>;
export type IOutageCaisoId = yup.Asserts<typeof OutageCaisoIdSchema>;

export type ILerRequestRadializedSubstation = yup.Asserts<
  typeof LerRequestRadializedSubstationSchema
>;
export type ILerRequestItem = yup.Asserts<typeof LerRequestItemSchema>;
export type ILerRequestItemSearchListPayload = yup.Asserts<
  typeof LerRequestItemSearchListPayloadSchema
>;
export type ILerRequestCancelForm = yup.Asserts<
  typeof LerRequestCancelFormSchema
>;
export type ILerRequestRejectSchema = yup.Asserts<
  typeof LerRequestRejectFormSchema
>;
export type ILerRequestCost = yup.Asserts<typeof LerRequestCostSchema>;

export type ILerRequestEmailGroup = yup.Asserts<typeof EmailGroupSchema>;
export type ILerRequestSystem = yup.Asserts<typeof LerRequestSystemSchema>;

export type ITransmissionOutage = yup.Asserts<typeof TransmissionOutageSchema>;
export type IPeriod = yup.Asserts<typeof PeriodSchema>;
export type ILerRequestEtsFormDispatchForm = yup.Asserts<
  typeof LerRequestEtsFormDispatchFormSchema
>;
export type ILerRequestEtsFormDispatch = yup.Asserts<
  typeof LerRequestEtsFormDispatchSchema
>;
export type ILerRequestEmployee = yup.Asserts<typeof LerRequestEmployeeSchema>;
export type IEmailStatusMessage = yup.Asserts<typeof EmailStatusMessageSchema>;
export type ISystemChangeTasksEmail = yup.Asserts<
  typeof SystemChangeTasksEmailSchema
>;
export type IQueryMessageCount = yup.Asserts<typeof QueryMessageCountSchema>;
export type ILerRequestRelatedLerItem = yup.Asserts<
  typeof LerRequestRelatedLerItemSchema
>;
export type ILerRequestOutageReject = yup.Asserts<
  typeof LerRequestOutageReject
>;
