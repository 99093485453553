import { Mark, mergeAttributes } from '@tiptap/core';

export const CustomSpan = Mark.create({
  name: 'customSpan',

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.className,
        renderHTML: (attributes) => {
          if (!attributes.class) return {};
          return { class: attributes.class };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span.custom-span',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { class: 'custom-span' }), 0];
  },

});
