/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import {
  ATMDropdown,
  ATMGrid,
  ATMField,
  ATMForm,
  ATMTextArea,
  ATMCheckbox,
  ATMSelect,
  ATMInput,
  ATMFormProvider,
  useATMFormContext,
  ATMDatePicker,
  formatTime,
  ATMRadio,
  ATMHeader,
  ATMTable,
  MOLInfoText,
  ATMButton,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import Lang from 'src/libraries/language';
import { useSubstationContext } from 'src/contexts/substation.context';
import {
  RealTimeLogEntryFormSchema,
  IRealTimeLogEntryForm,
  IRealTimeLog,
} from 'src/models/real-time-log.model';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { getUser } from 'src/libraries/amplify.library';
import { useFacilityContext } from 'src/contexts/facility.context';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { substationActionTypes } from 'src/ducks/substation.duck';
import { format24hTime, format24hDateTime } from 'src/libraries/moment.library';
import { formatToRawEmployeeId } from 'src/helpers/employee.helper';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import {
  RealTimeLogCategoryText,
  RealTimeLogCategory,
  BporReportQualified,
  BporCategoryQualifications,
} from 'src/constants/real-time-log.constant';
import { addMonths } from 'date-fns';
import { REGEX_24HR_TIME } from 'src/constants/regex.constant';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import TripsAndTargetsForm from './trips-targets-form/trips-targets-form.component';
import style from './real-time-log-form.module.scss';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IRealTimeLog>;
  handleSubmit: (data: IRealTimeLogEntryForm) => void;
  handleEnable: (value: boolean) => void;
  handleClick?: () => void;
  setIsChangeLogUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  changeLogUpdateComplete?: boolean;
  setChangeLogUpdateComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  setChangeLogOnEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{
      index: number;
      logSub: string;
      tripsTargets: string;
    } | null>
  >;
};

type IFormContent = {
  defaultValues?: Partial<IRealTimeLog>;
  handleClick?: () => void;
  setIsChangeLogUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  changeLogUpdateComplete?: boolean;
  setChangeLogUpdateComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  setChangeLogOnEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
  setTripOnEdit: React.Dispatch<
    React.SetStateAction<{
      index: number;
      logSub: string;
      tripsTargets: string;
    } | null>
  >;
};

const FormContent: React.FC<IFormContent> = ({
  defaultValues,
  handleClick,
  setIsChangeLogUpdate,
  changeLogUpdateComplete,
  setChangeLogUpdateComplete,
  setChangeLogOnEditMode,
  loading,
  setTripOnEdit,
}) => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    resetField,
    clearErrors,
  } = useATMFormContext<IRealTimeLogEntryForm>();
  const { state: substationState } = useSubstationContext();
  const { state: realTimeLogState } = useRealTimeLogContext();
  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();

  // const lineStatus = getRealTimeLogStatus(
  //   realTimeLogState,
  //   realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  // );

  const lineCreateStatus = getRealTimeLogStatus(
    realTimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_CREATE
  );

  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_CREATE_READ
  );

  const voltStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_VOLT_SUBS_READ
  );

  const equipmentStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_EQUIPMENT_READ
  );

  const equipmentTypeStatus = getEquipmentTypeStatus(
    equipmentTypeState,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );

  const [category, setCategory] = useState(defaultValues?.logCategory);
  const [userType, setUserType] = useState(defaultValues?.logUserType);
  const [isDirty, setIsDirty] = useState(false);
  const [date] = useState(new Date());
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const [dateFrcdOutOccSelected, setDateFrcdOutOccSelected] = useState<
    Date | undefined | null
  >(defaultValues?.logDtTmFrcdOutOcurred);
  const [dateFrcdOutRestSelected, setDateFrcdOutRestSelected] = useState<
    Date | undefined | null
  >(defaultValues?.logDtTmFrcdOutRestored);
  const [originalRestoration] = useState<Date | null>(
    defaultValues?.logDtTmFrcdOutRestored ?? null
  );

  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);

  const [isSelectedSubs, setIsSelectedSubs] = useState(
    !!(defaultValues && defaultValues.logSubstation)
  );
  const [lineSubstationSelect, setLineSubstationSelect] = useState<string>(
    defaultValues && defaultValues.logFacId && defaultValues.logSubstation
      ? 'substation'
      : defaultValues && defaultValues.logFacId
      ? 'line'
      : ''
  );
  const [equipmentType, setEquipmentType] = useState(
    defaultValues ? defaultValues.facility?.facTyp?.facTypId : 0
  );
  const [volt, setVolt] = useState();
  const [changeLogOnEdit, setChangeLogOnEdit] = useState<number | undefined>(
    undefined
  );
  const [changeLogValue, setChangeLogValue] = useState<string | null>(null);
  const [bporReportQualified, setBporReportQualified] =
    useState<boolean>(false);

  const handleOptionsVolt = useCallback(
    (equipment: any) => {
      if (getValues().logSubstation && equipment !== '') {
        facilityActions.voltSubstationGET(
          getValues().logSubstation ?? '',
          equipment
        );
      } else {
        facilityActions.voltCLEAR();
        facilityActions.equipmentCLEAR();
        resetField('volt');
        resetField('logFacId');
      }
    },
    [control]
  );

  const handleDateSelect = useCallback(
    (year, month, day, hour?, min?) => {
      if (hour !== undefined && min !== undefined) {
        setDateSelected(new Date(year, month, day, hour, min));
        setValue('logDtTm', new Date(year, month, day, hour, min));
      } else {
        setDateSelected(new Date(year, month, day));
        setValue('logDtTm', new Date(year, month, day));
      }
    },
    [setDateSelected]
  );

  const handleDateOccurredSelect = useCallback(
    (year, month, day, hour?, min?) => {
      if (hour !== undefined && min !== undefined) {
        setDateFrcdOutOccSelected(new Date(year, month, day, hour, min));
        setValue(
          'logDtTmFrcdOutOcurred',
          new Date(year, month, day, hour, min)
        );
      } else {
        setDateFrcdOutOccSelected(new Date(year, month, day));
        setValue('logDtTmFrcdOutOcurred', new Date(year, month, day));
      }
      setMinDate(new Date(year, month, day));
    },
    [setDateFrcdOutOccSelected, setValue, setMinDate]
  );

  const handleDateRestoredSelect = useCallback(
    (year, month, day, hour?, min?) => {
      if (hour !== undefined && min !== undefined) {
        setDateFrcdOutRestSelected(new Date(year, month, day, hour, min));
        setValue(
          'logDtTmFrcdOutRestored',
          new Date(year, month, day, hour, min)
        );
      } else {
        setDateFrcdOutRestSelected(new Date(year, month, day));
        setValue('logDtTmFrcdOutRestored', new Date(year, month, day));
      }
      setMaxDate(new Date(year, month, day));
    },
    [setDateFrcdOutRestSelected, setValue, setMaxDate]
  );

  const handleSubstationSelect = useCallback((substationId: any) => {
    const data = {
      limit: 0,
      filters: [{ name: 'substationId', value: substationId }],
    };
    equipmentTypeActions.listGET(data);
  }, []);

  const handleOptionsEquipment = useCallback(
    (voltNum: any) => {
      if (getValues().logSubstation && equipmentType && voltNum !== '') {
        facilityActions.equipmentGET(
          equipmentType,
          voltNum,
          undefined,
          getValues().logSubstation ?? ''
        );
      } else {
        facilityActions.equipmentCLEAR();
        resetField('logFacId');
      }
    },
    [equipmentType, control]
  );

  useEffect(() => {
    if (
      category &&
      Object.values(BporReportQualified).includes(
        category as BporReportQualified
      )
    ) {
      setBporReportQualified(true);
    } else {
      setBporReportQualified(false);
    }
  }, [category, setBporReportQualified]);

  useEffect(() => {
    if (!defaultValues?.logDtTm) {
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      const hour = date.getHours();
      const min = date.getMinutes();
      handleDateSelect(year, month, day, hour, min);
    }

    facilityActions?.voltCLEAR();
    facilityActions?.equipmentCLEAR();
    equipmentTypeActions?.listCLEAR();

    if (defaultValues && defaultValues?.logSubstation) {
      facilityActions?.voltSubstationGET(
        defaultValues.logSubstation,
        defaultValues.facility?.facTyp?.facTypId ?? 21
      );
      facilityActions?.equipmentGET(
        defaultValues.facility?.facTyp?.facTypId ?? 2,
        defaultValues.facility?.volt.voltId ?? 0,
        undefined,
        defaultValues.logSubstation
      );
      const data = {
        limit: 0,
        filters: [{ name: 'substationId', value: defaultValues.logSubstation }],
      };
      equipmentTypeActions?.listGET(data);
    }

    if (defaultValues) {
      if (defaultValues.logFacId && defaultValues.logSubstation) {
        setValue('lineSubstation', 'substation');
      } else if (defaultValues && defaultValues.logFacId) {
        setValue('lineSubstation', 'line');
      }

      if (defaultValues.logDtTmFrcdOutOcurred) {
        setMinDate(
          new Date(
            defaultValues.logDtTmFrcdOutOcurred.getFullYear(),
            defaultValues.logDtTmFrcdOutOcurred.getMonth(),
            defaultValues.logDtTmFrcdOutOcurred.getDate()
          )
        );
      }

      if (
        defaultValues.logCategory &&
        [
          RealTimeLogCategory.FORCEDOUTAGE,
          RealTimeLogCategory.RCBANOTIF,
          RealTimeLogCategory.FIELDNOTIF,
          RealTimeLogCategory.EMERGENCYEVENT,
        ].includes(defaultValues.logCategory as RealTimeLogCategory)
      ) {
        setValue('logBporReport', true);
      }
    }
    setValue('changeLogForUpdate', null);
    setChangeLogOnEdit(undefined);
  }, []);

  useEffect(() => {
    if (changeLogUpdateComplete) {
      setChangeLogOnEdit(undefined);
      setValue('changeLogForUpdate', null);
    }
  }, [changeLogUpdateComplete]);

  useEffect(() => {
    sessionStorage.setItem('userType', getValues('logUserType'));
  }, [control]);

  return (
    <>
      <ATMGrid columns={3} className={style.datePicker}>
        <ATMGrid.Row>
          <ATMField
            control={control}
            as={ATMInput}
            name="logUserId"
            defaultValue={getUser()?.emp_no}
            type="hidden"
            className="hidden"
          />
          <ATMGrid.Column width={5}>
            <span className={style.dateRange}>
              <ATMField
                as={ATMDatePicker}
                className={style.dateRange}
                label={<LabelRequired>{Lang.LBL_DATE}</LabelRequired>}
                dateFormat="MM-DD-YYYY"
                maxDate={addMonths(date, 6)}
                defaultValue={
                  defaultValues?.logDtTm
                    ? new Date(defaultValues?.logDtTm)
                    : date
                }
                isDateTimePicker
                dateTimePlaceHolder="Select"
                control={control}
                name="logDtTm"
                error={errors.logDtTm}
                onChange={([_, val]) => {
                  const day = val?.value?.getDate();
                  const month = val?.value?.getMonth();
                  const year = val?.value?.getFullYear();

                  handleDateSelect(year, month, day);

                  const hourMin = getValues('logTm');
                  const formattedTime = formatTime(hourMin);

                  if (formattedTime) {
                    const regArray = REGEX_24HR_TIME.exec(formattedTime);

                    if (regArray && regArray.length === 2) {
                      const hour = Number(formattedTime.split(':')[0]);
                      const min = Number(formattedTime.split(':')[1]);

                      if (!(hour > 23 || min > 59)) {
                        handleDateSelect(year, month, day, hour, min);
                      }
                    }
                  }

                  return val.value;
                }}
              />
            </span>
          </ATMGrid.Column>
          <ATMGrid.Column width={5}>
            <ATMField
              as={ATMInput}
              name="logTm"
              label={<LabelRequired>{Lang.LBL_TIME}</LabelRequired>}
              control={control}
              error={errors.logTm}
              autoComplete="off"
              defaultValue={
                defaultValues && defaultValues.logDtTm
                  ? format24hTime(defaultValues?.logDtTm)
                  : format24hTime(date)
              }
              value={
                defaultValues && defaultValues.logDtTm
                  ? format24hTime(defaultValues?.logDtTm)
                  : format24hTime(date)
              }
              maxLength={5}
              placeholder="hh:mm"
              onChange={([_, { value }]) => {
                const formattedTime = formatTime(value);
                if (formattedTime) {
                  const regArray = REGEX_24HR_TIME.exec(formattedTime);

                  const day = dateSelected?.getDate();
                  const month = dateSelected?.getMonth();
                  const year = dateSelected?.getFullYear();

                  if (regArray && regArray.length === 2) {
                    const hour = Number(formattedTime.split(':')[0]);
                    const min = Number(formattedTime.split(':')[1]);

                    if (!(hour > 23 || min > 59)) {
                      handleDateSelect(year, month, day, hour, min);
                    } else {
                      handleDateSelect(year, month, day);
                    }
                  } else {
                    handleDateSelect(year, month, day);
                  }
                }
                return formattedTime;
              }}
              clearable
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row>
          <ATMGrid.Column width={5}>
            <span className="field">
              <LabelRequired>{Lang.LBL_USER_TYPE}</LabelRequired>
            </span>
            <div id="logUserTypeGroup" className={style.flexed}>
              <span
                className={style.flexed}
                style={{
                  marginRight: '2em',
                }}
              >
                <ATMField
                  as={ATMRadio}
                  control={control}
                  checked={
                    userType
                      ? !!(userType === 'TSO')
                      : !!(sessionStorage.getItem('userType') === 'TSO')
                  }
                  value="TSO"
                  name="logUserTypeGroup"
                  onChange={([_, { checked }]) => {
                    setUserType('TSO');
                    setValue('logUserType', 'TSO');
                    clearErrors('logUserType');
                    return checked;
                  }}
                  error={errors.logUserType}
                  className={style.compactRadioError}
                />
                <span className="field">{Lang.LBL_TSO}</span>
              </span>
              <span className={style.flexed}>
                <ATMField
                  as={ATMRadio}
                  control={control}
                  checked={
                    userType
                      ? !!(userType === 'OSS')
                      : !!(sessionStorage.getItem('userType') === 'OSS')
                  }
                  value="OSS"
                  name="logUserTypeGroup"
                  onChange={([_, { checked }]) => {
                    setUserType('OSS');
                    setValue('logUserType', 'OSS');
                    clearErrors('logUserType');
                    return checked;
                  }}
                  error={errors.logUserType}
                  className={style.compactRadioHiddenError}
                />
                <span className="field">{Lang.LBL_OSS}</span>
              </span>
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column width={7}>
            <ATMField
              as={ATMSelect}
              label={Lang.LBL_CATEGORY}
              name="logCategory"
              placeholder="Category"
              size="small"
              options={[
                ...Array.from(Object.keys(Lang.REAL_TIME_LOG_STATUS)).map(
                  (val) => {
                    return {
                      key: val,
                      value: val,
                      text: Lang.REAL_TIME_LOG_STATUS[val],
                    };
                  }
                ),
              ]}
              disabled={
                defaultValues?.logCategory === RealTimeLogCategory.FORCEDOUTAGE
              }
              onChange={([_, { value }]) => {
                setCategory(value);
                setValue('changeLogNote', undefined);
                if (
                  defaultValues?.logCategory &&
                  value === defaultValues?.logCategory &&
                  value === RealTimeLogCategory.FORCEDOUTAGE
                ) {
                  setValue('logEntry', defaultValues?.logEntry as string);
                } else if (value) {
                  if (!defaultValues?.logEntry) {
                    setValue('logEntry', RealTimeLogCategoryText[value]);
                  }
                } else if (!value) {
                  if (!defaultValues?.logEntry) {
                    setValue('logEntry', '');
                  }
                }

                if (value !== RealTimeLogCategory.FORCEDOUTAGE) {
                  if (
                    !defaultValues?.forcedLogSubs ||
                    defaultValues?.forcedLogSubs.length === 0
                  ) {
                    setValue('forcedLogSubs', []);
                    setValue('forcedLog', []);
                  }

                  if (!defaultValues?.logDtTmFrcdOutOcurred) {
                    setValue('logDtTmFrcdOutOcurred', undefined);
                    setValue('logTmFrcdOutOccurred', undefined);
                    setDateFrcdOutOccSelected(undefined);
                  }

                  if (!defaultValues?.logDtTmFrcdOutRestored) {
                    setValue('logDtTmFrcdOutRestored', undefined);
                    setValue('logTmFrcdOutRestored', undefined);
                    setDateFrcdOutRestSelected(undefined);
                  }
                }

                if (value !== defaultValues?.logCategory) {
                  resetField('logBporReport');
                  setValue('logBporReport', false);
                }

                if (
                  [
                    RealTimeLogCategory.FORCEDOUTAGE,
                    RealTimeLogCategory.RCBANOTIF,
                    RealTimeLogCategory.FIELDNOTIF,
                    RealTimeLogCategory.EMERGENCYEVENT,
                  ].includes(value as RealTimeLogCategory)
                ) {
                  setValue('logBporReport', true);
                }

                resetField('lineSubstation', {
                  defaultValue: getValues('lineSubstation') ?? undefined,
                });
                resetField('logEntry', {
                  defaultValue: getValues('logEntry') ?? undefined,
                });

                return value;
              }}
              control={control}
              error={errors.logCategory}
              clearable
              search
              selectOnBlur={false}
            />
            {category === RealTimeLogCategory.CONTROLEQUIPOUTAGE ? (
              <span className={style.emphasized}>
                Note:
                <LabelRequired left>
                  {' '}
                  Call CAISO or Send GMS message
                </LabelRequired>
              </span>
            ) : (
              ''
            )}
          </ATMGrid.Column>
          {category && bporReportQualified && (
            // <ATMGrid.Column width={4} id="report">
            <ATMGrid.Column
              width={4}
              id="report"
              className={
                [
                  RealTimeLogCategory.FORCEDOUTAGE,
                  RealTimeLogCategory.RCBANOTIF,
                  RealTimeLogCategory.FIELDNOTIF,
                  RealTimeLogCategory.EMERGENCYEVENT,
                ].includes(category as RealTimeLogCategory)
                  ? style.hidden
                  : ''
              }
            >
              <ATMField
                as={ATMCheckbox}
                control={control}
                name="logBporReport"
                label={BporCategoryQualifications[category]}
                onChange={([_, { checked }]) => checked}
              />
            </ATMGrid.Column>
          )}
        </ATMGrid.Row>
        {category === RealTimeLogCategory.FORCEDOUTAGE && (
          <>
            {' '}
            <ATMGrid.Row>
              <ATMGrid.Column width={5}>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="forcedLogSubs"
                  label={Lang.LBL_SUBS_INTERRUPTED}
                  error={errors.forcedLogSubs}
                  selection
                  clearable
                  multiple
                  placeholder="Select"
                  selectOnBlur={false}
                  options={substationState.listForCreate.map((value) => ({
                    key: value.substationId,
                    value: value.substationId,
                    text: value.name,
                  }))}
                  onChange={([_, { value }]) => value}
                  search
                  loading={substationStatus.fetching}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column width={5}>
                <ATMField
                  as={ATMDatePicker}
                  label={Lang.LBL_DATE_TIME_OCURRED}
                  className={style.dateRange}
                  isDateTimePicker
                  dateFormat="MM-DD-YYYY"
                  dateTimePlaceHolder="Select"
                  defaultValue={
                    defaultValues?.logDtTmFrcdOutOcurred
                      ? new Date(defaultValues?.logDtTmFrcdOutOcurred)
                      : undefined
                  }
                  control={control}
                  name="logDtTmFrcdOutOcurred"
                  error={errors.logDtTmFrcdOutOcurred}
                  maxDate={maxDate}
                  onChange={([_, val]) => {
                    if (!val.value) {
                      setValue('logTmFrcdOutOccurred', undefined);
                      setDateFrcdOutOccSelected(undefined);
                      resetField('logTmFrcdOutOccurred', {
                        defaultValue: null,
                      });
                      setMinDate(undefined);
                    } else {
                      const day = val?.value?.getDate();
                      const month = val?.value?.getMonth();
                      const year = val?.value?.getFullYear();
                      handleDateOccurredSelect(year, month, day);

                      const hourMin = getValues('logTmFrcdOutOccurred');
                      const formattedTime = formatTime(hourMin ?? '');

                      if (formattedTime) {
                        const regArray = REGEX_24HR_TIME.exec(formattedTime);
                        if (regArray && regArray.length === 2) {
                          const hour = Number(formattedTime.split(':')[0]);
                          const min = Number(formattedTime.split(':')[1]);

                          if (!(hour > 23 || min > 59)) {
                            handleDateOccurredSelect(
                              year,
                              month,
                              day,
                              hour,
                              min
                            );
                          }
                        }
                      }
                    }
                    return val.value;
                  }}
                />
              </ATMGrid.Column>
              <ATMGrid.Column width={5}>
                <ATMField
                  as={ATMInput}
                  name="logTmFrcdOutOccurred"
                  label={<label>&nbsp;</label>}
                  control={control}
                  error={errors.logTmFrcdOutOccurred}
                  defaultValue={
                    defaultValues && defaultValues.logDtTmFrcdOutOcurred
                      ? format24hTime(defaultValues?.logDtTmFrcdOutOcurred)
                      : undefined
                  }
                  autoComplete="off"
                  value=""
                  maxLength={5}
                  placeholder="hh:mm"
                  disabled={!dateFrcdOutOccSelected}
                  onChange={([_, { value }]) => {
                    const formattedTime = formatTime(value);

                    if (formattedTime) {
                      const regArray = REGEX_24HR_TIME.exec(formattedTime);

                      const day = dateFrcdOutOccSelected?.getDate();
                      const month = dateFrcdOutOccSelected?.getMonth();
                      const year = dateFrcdOutOccSelected?.getFullYear();

                      if (regArray && regArray.length === 2) {
                        const hour = Number(formattedTime.split(':')[0]);
                        const min = Number(formattedTime.split(':')[1]);

                        if (!(hour > 23 || min > 59)) {
                          handleDateOccurredSelect(year, month, day, hour, min);
                        } else {
                          handleDateOccurredSelect(year, month, day);
                        }
                      } else {
                        handleDateOccurredSelect(year, month, day);
                      }
                    }
                    return formattedTime;
                  }}
                  clearable
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column width={5}>
                <ATMField
                  as={ATMDatePicker}
                  label={Lang.LBL_DATE_TIME_RESTORED}
                  className={style.dateRange}
                  isDateTimePicker
                  dateTimePlaceHolder="Select"
                  dateFormat="MM-DD-YYYY"
                  defaultValue={
                    defaultValues?.logDtTmFrcdOutRestored
                      ? new Date(defaultValues?.logDtTmFrcdOutRestored)
                      : undefined
                  }
                  control={control}
                  name="logDtTmFrcdOutRestored"
                  error={errors.logDtTmFrcdOutRestored}
                  minDate={minDate}
                  onChange={([_, val]) => {
                    if (!val.value) {
                      setValue('logTmFrcdOutRestored', undefined);
                      setDateFrcdOutRestSelected(undefined);
                      resetField('logTmFrcdOutRestored', {
                        defaultValue: null,
                      });
                      setMaxDate(undefined);
                    } else {
                      const day = val?.value?.getDate();
                      const month = val?.value?.getMonth();
                      const year = val?.value?.getFullYear();
                      handleDateRestoredSelect(year, month, day);

                      const hourMin = getValues('logTmFrcdOutRestored');
                      const formattedTime = formatTime(hourMin ?? '');

                      if (formattedTime) {
                        const regArray = REGEX_24HR_TIME.exec(formattedTime);

                        if (regArray && regArray.length === 2) {
                          const hour = Number(formattedTime.split(':')[0]);
                          const min = Number(formattedTime.split(':')[1]);

                          if (!(hour > 23 || min > 59)) {
                            handleDateRestoredSelect(
                              year,
                              month,
                              day,
                              hour,
                              min
                            );
                          }
                        }
                      }
                    }
                    return val.value;
                  }}
                />
              </ATMGrid.Column>
              <ATMGrid.Column width={5}>
                <ATMField
                  control={control}
                  as={ATMInput}
                  name="originalRestoration"
                  defaultValue={originalRestoration}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  as={ATMInput}
                  name="logTmFrcdOutRestored"
                  label={<label>&nbsp;</label>}
                  control={control}
                  error={errors.logTmFrcdOutRestored}
                  defaultValue={
                    defaultValues && defaultValues.logDtTmFrcdOutRestored
                      ? format24hTime(defaultValues?.logDtTmFrcdOutRestored)
                      : undefined
                  }
                  autoComplete="off"
                  value=""
                  disabled={!dateFrcdOutRestSelected}
                  maxLength={5}
                  placeholder="hh:mm"
                  onChange={([_, { value }]) => {
                    const formattedTime = formatTime(value);
                    if (formattedTime) {
                      const regArray = REGEX_24HR_TIME.exec(formattedTime);

                      const day = dateFrcdOutRestSelected?.getDate();
                      const month = dateFrcdOutRestSelected?.getMonth();
                      const year = dateFrcdOutRestSelected?.getFullYear();

                      if (regArray && regArray.length === 2) {
                        const hour = Number(formattedTime.split(':')[0]);
                        const min = Number(formattedTime.split(':')[1]);

                        if (!(hour > 23 || min > 59)) {
                          handleDateRestoredSelect(year, month, day, hour, min);
                        } else {
                          handleDateRestoredSelect(year, month, day);
                        }
                      } else {
                        handleDateRestoredSelect(year, month, day);
                      }
                    }
                    return formattedTime;
                  }}
                  clearable
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </>
        )}
        <ATMGrid.Row>
          <ATMGrid.Column width={5}>
            <span className="field">
              {category === RealTimeLogCategory.FORCEDOUTAGE ? (
                <LabelRequired>{Lang.LBL_LINE_SUBSTATION_SELECT}</LabelRequired>
              ) : (
                Lang.LBL_LINE_SUBSTATION_SELECT
              )}
            </span>
            <div id="lineSubstationGroup" className={style.flexed}>
              <span
                className={style.flexed}
                style={{
                  marginRight: '2em',
                }}
              >
                <ATMField
                  as={ATMRadio}
                  control={control}
                  checked={!!(lineSubstationSelect === 'line')}
                  value="line"
                  name="lineSubstationGroup"
                  onChange={([_, { checked }]) => {
                    setLineSubstationSelect('line');
                    setValue('lineSubstation', 'line');
                    setIsDirty(true);
                    setIsSelectedSubs(false);
                    facilityActions?.voltCLEAR();
                    facilityActions?.equipmentCLEAR();
                    equipmentTypeActions?.listCLEAR();
                    setEquipmentType(0);
                    setValue('equipmentType', null);
                    setValue('volt', null);
                    setValue('logFacId', null);
                    setValue('logSubstation', null);
                    clearErrors([
                      'lineSubstation',
                      'logSubstation',
                      'equipmentType',
                      'volt',
                      'logFacId',
                    ]);
                    return checked;
                  }}
                  error={errors.lineSubstation}
                  className={style.compactRadioError}
                />
                <span className="field">{Lang.LBL_LINE}</span>
              </span>
              <span className={style.flexed}>
                <ATMField
                  as={ATMRadio}
                  control={control}
                  checked={!!(lineSubstationSelect === 'substation')}
                  value="substation"
                  name="lineSubstationGroup"
                  onChange={([_, { checked }]) => {
                    setLineSubstationSelect('substation');
                    setValue('lineSubstation', 'substation');
                    setIsDirty(true);
                    setIsSelectedSubs(false);
                    facilityActions?.voltCLEAR();
                    facilityActions?.equipmentCLEAR();
                    equipmentTypeActions?.listCLEAR();
                    setEquipmentType(0);
                    setValue('equipmentType', null);
                    setValue('volt', null);
                    setValue('logFacId', null);
                    setValue('logSubstation', null);
                    clearErrors([
                      'lineSubstation',
                      'logSubstation',
                      'equipmentType',
                      'volt',
                      'logFacId',
                    ]);
                    return checked;
                  }}
                  error={errors.lineSubstation}
                  className={style.compactRadioHiddenError}
                />
                <span className="field">{Lang.LBL_SUBSTATION}</span>
              </span>
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column stretched width={10}>
            {lineSubstationSelect ? (
              lineSubstationSelect === 'line' ? (
                <ATMField
                  as={ATMDropdown}
                  selection
                  name="logFacId"
                  label={<label>&nbsp;</label>}
                  placeholder={Lang.LBL_SELECT}
                  size="small"
                  disabled={!defaultValues ? !isDirty : false}
                  options={realTimeLogState.lineCreateList.map((value) => {
                    return {
                      key: value.outgFacId,
                      value: value.outgFacId,
                      text: value.outgFacNm,
                    };
                  })}
                  onChange={([_, { value }]) => value ?? undefined}
                  loading={lineCreateStatus.fetching}
                  control={control}
                  error={errors.logFacId}
                  clearable
                  search
                  selectOnBlur={false}
                />
              ) : (
                <ATMField
                  as={ATMDropdown}
                  selection
                  name="logSubstation"
                  label={<label>&nbsp;</label>}
                  placeholder={Lang.LBL_SELECT}
                  size="small"
                  disabled={!defaultValues ? !isDirty : false}
                  options={substationState.listForCreate.map((value) => ({
                    key: value.substationId,
                    value: value.substationId,
                    text: value.name,
                  }))}
                  onChange={([_, { value }]) => {
                    if (value) {
                      handleSubstationSelect(value);
                      setIsSelectedSubs(true);
                    } else {
                      setIsSelectedSubs(false);
                    }
                    facilityActions?.voltCLEAR();
                    facilityActions?.equipmentCLEAR();
                    setEquipmentType(0);
                    setValue('equipmentType', null);
                    setValue('volt', null);
                    setValue('logFacId', null);
                    clearErrors(['equipmentType', 'volt', 'logFacId']);
                    return value;
                  }}
                  control={control}
                  error={errors.logSubstation}
                  loading={substationStatus.fetching}
                  clearable
                  search
                  selectOnBlur={false}
                />
              )
            ) : (
              <ATMField
                as={ATMDropdown}
                selection
                name="disabledFacilities"
                label={<label>&nbsp;</label>}
                placeholder={Lang.LBL_SELECT}
                size="small"
                disabled
                options={[]}
                clearable
              />
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
        {lineSubstationSelect === 'substation' && (
          <ATMGrid.Row>
            <ATMGrid.Column width={5}>
              <ATMField
                as={ATMDropdown}
                name="equipmentType"
                label={<LabelRequired>{Lang.LBL_EQUIPMENT_TYPE}</LabelRequired>}
                control={control}
                key={
                  defaultValues
                    ? `equipmentType${defaultValues?.logId}`
                    : 'equipmentType$1'
                }
                selection
                clearable
                selectOnBlur={false}
                error={errors.equipmentType}
                placeholder="Select"
                options={equipmentTypeState.list.map((value) => ({
                  key: value.facTypId,
                  value: value.facTypId,
                  text: value.facTypNm,
                }))}
                disabled={!isSelectedSubs || equipmentTypeStatus.fetching}
                onChange={([_, { value }]) => {
                  if (value) {
                    setEquipmentType(value);
                    handleOptionsVolt(value);
                    setValue('volt', null);
                    setValue('logFacId', null);
                    facilityActions?.equipmentCLEAR();
                  } else {
                    facilityActions?.voltCLEAR();
                    facilityActions?.equipmentCLEAR();
                    setEquipmentType(0);
                    setValue('equipmentType', null);
                    setValue('volt', null);
                    setValue('logFacId', null);
                  }
                  clearErrors(['volt', 'logFacId']);
                  return value;
                }}
                defaultValue={defaultValues ? equipmentType : ''}
                loading={equipmentTypeStatus.fetching}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={5}>
              <ATMField
                as={ATMDropdown}
                name="volt"
                label={<LabelRequired>{Lang.LBL_VOLTAGE}</LabelRequired>}
                key={defaultValues ? `volt${defaultValues?.logId}` : 'volt$1'}
                defaultValue={
                  defaultValues ? defaultValues.facility?.volt.voltId : ''
                }
                control={control}
                selection
                clearable
                selectOnBlur={false}
                error={errors.volt}
                loading={voltStatus.fetching}
                placeholder="Select"
                options={facilityState.volt?.map((val) => ({
                  key: val.voltId,
                  value: val.voltId,
                  text: val.name,
                }))}
                disabled={
                  equipmentType === 0 ||
                  facilityState.volt?.length === 0 ||
                  voltStatus.fetching
                }
                onChange={([_, { value }]) => {
                  setVolt(value);
                  clearErrors('logFacId');
                  handleOptionsEquipment(value);
                  return value;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column width={5}>
              <ATMField
                as={ATMDropdown}
                name="logFacId"
                label={<LabelRequired>{Lang.LBL_EQUIPMENT}</LabelRequired>}
                control={control}
                key={
                  defaultValues
                    ? `equipment${defaultValues?.logId}`
                    : 'equipment$1'
                }
                selection
                clearable
                selectOnBlur={false}
                loading={equipmentStatus.fetching}
                error={errors.logFacId}
                placeholder="Select"
                options={facilityState.equipment?.map((val) => ({
                  key: val.outgFacId,
                  value: val.outgFacId,
                  text: val.outgFacNm,
                }))}
                defaultValue={
                  defaultValues ? defaultValues.facility?.outgFacId : undefined
                }
                disabled={
                  volt === '' ||
                  facilityState.equipment?.length === 0 ||
                  equipmentStatus.fetching
                }
                onChange={([_, { value }]) => value ?? undefined}
              />
            </ATMGrid.Column>
          </ATMGrid.Row>
        )}
        <ATMGrid.Row columns={5} id="secondRow">
          <ATMGrid.Column width={10}>
            <ATMField
              as={ATMTextArea}
              label={
                <span>
                  <LabelRequired>{Lang.LBL_LOG_ENTRY}</LabelRequired>
                  <span className={style.emphasized}>
                    Review text in uppercase or in brackets
                  </span>
                </span>
              }
              name="logEntry"
              size="small"
              placeholder="Enter Log"
              maxLength={2500}
              control={control}
              error={errors.logEntry}
              rows={4}
              readOnly={
                defaultValues?.logCategory ===
                  RealTimeLogCategory.FORCEDOUTAGE &&
                category === RealTimeLogCategory.FORCEDOUTAGE &&
                formatToRawEmployeeId(defaultValues?.logUserId) !==
                  getUser()?.emp_no
              }
              className={
                defaultValues?.logCategory ===
                  RealTimeLogCategory.FORCEDOUTAGE &&
                category === RealTimeLogCategory.FORCEDOUTAGE &&
                formatToRawEmployeeId(defaultValues?.logUserId) !==
                  getUser()?.emp_no
                  ? style.disabledField
                  : ''
              }
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
        {category === RealTimeLogCategory.FORCEDOUTAGE && (
          <TripsAndTargetsForm setTripOnEdit={setTripOnEdit} />
        )}
        {category === RealTimeLogCategory.FORCEDOUTAGE && defaultValues?.logId && (
          <>
            <ATMGrid.Row>
              <ATMGrid.Column width={10}>
                <ATMField
                  as={ATMTextArea}
                  label={
                    <LabelRequired>{Lang.LBL_ADD_ADDITIONAL_LOG}</LabelRequired>
                  }
                  name="changeLogNote"
                  size="small"
                  placeholder="Enter additional logs"
                  maxLength={2500}
                  control={control}
                  error={errors.changeLogNote}
                  rows={4}
                  defaultValue=""
                  onChange={([_, { value }]) => value ?? undefined}
                  autoComplete="off"
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMHeader
              className={style.changeLog}
              content={Lang.LBL_ADDITIONAL_LOGS}
            />
            <ATMGrid.Row columns={1}>
              <ATMGrid.Column fluid="true">
                <ATMTable className={style.changeLog}>
                  <ATMTable.Header>
                    <ATMTable.Row>
                      <ATMTable.HeaderCell width={3}>
                        {Lang.LBL_UPDATE_DATE}
                      </ATMTable.HeaderCell>
                      <ATMTable.HeaderCell width={3}>
                        {Lang.LBL_UPDATED_BY}
                      </ATMTable.HeaderCell>
                      <ATMTable.HeaderCell width={8}>
                        {Lang.LBL_ADD_ADDITIONAL_LOG}
                      </ATMTable.HeaderCell>
                      <ATMTable.HeaderCell width={2}>
                        {Lang.LBL_ACTION}
                      </ATMTable.HeaderCell>
                    </ATMTable.Row>
                  </ATMTable.Header>
                  <ATMTable.Body>
                    {defaultValues.changeLog &&
                      defaultValues.changeLog.map((item, i) => (
                        <ATMTable.Row key={i}>
                          <ATMTable.Cell>
                            {format24hDateTime(item.updatedAt)}
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            {item.emp.lastName
                              ? `${item.emp.lastName}, ${item.emp.firstName}`
                              : item.updatedBy}
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            {changeLogOnEdit !== i ? (
                              <div className={style.changeLog}>
                                <MOLInfoText
                                  transparent
                                  limit={80}
                                  content={item.changeLogNote}
                                />
                              </div>
                            ) : (
                              <ATMField
                                as={ATMTextArea}
                                name={`changeLog[${i}].changeLogNote`}
                                size="small"
                                placeholder="Update this log"
                                maxLength={2500}
                                control={control}
                                rows={4}
                                defaultValue={item.changeLogNote}
                                readOnly={loading}
                                className={loading ? style.disabledField : ''}
                                onChange={([_, { value }]) => {
                                  let tempVal = value;
                                  if (value) {
                                    tempVal = value.trim();
                                    setChangeLogValue(tempVal);
                                  }

                                  return tempVal ?? undefined;
                                }}
                                autoComplete="off"
                              />
                            )}
                          </ATMTable.Cell>
                          <ATMTable.Cell>
                            {changeLogOnEdit !== i ? (
                              <ATMButton
                                icon="edit"
                                type="button"
                                onClick={() => {
                                  setChangeLogOnEdit(i);
                                  if (setIsChangeLogUpdate) {
                                    setIsChangeLogUpdate(true);
                                  }
                                  if (setChangeLogOnEditMode) {
                                    setChangeLogOnEditMode(true);
                                  }
                                }}
                                className={
                                  formatToRawEmployeeId(item.emp.empId) !==
                                  getUser()?.emp_no
                                    ? style.hidden
                                    : ''
                                }
                                loading={loading}
                              />
                            ) : (
                              <>
                                <ATMButton
                                  icon="save"
                                  type="button"
                                  onClick={() => {
                                    setValue('changeLogForUpdate', {
                                      logId: item.logId,
                                      noteId: item.noteId,
                                      changeLogNote:
                                        changeLogValue ??
                                        item.changeLogNote ??
                                        '-',
                                    });
                                    if (setChangeLogUpdateComplete) {
                                      setChangeLogUpdateComplete(false);
                                    }
                                    if (setChangeLogOnEditMode) {
                                      setChangeLogOnEditMode(true);
                                    }
                                    if (handleClick) {
                                      handleClick();
                                    }
                                  }}
                                  loading={loading}
                                />
                                <ATMButton
                                  icon="cancel"
                                  type="button"
                                  onClick={() => {
                                    setChangeLogOnEdit(undefined);
                                    setChangeLogValue(item.changeLogNote);
                                    if (setIsChangeLogUpdate) {
                                      setIsChangeLogUpdate(false);
                                    }
                                    setValue('changeLogForUpdate', null);
                                    setValue(
                                      `changeLog[${i}].changeLogNote` as any,
                                      item.changeLogNote
                                    );
                                    if (setChangeLogOnEditMode) {
                                      setChangeLogOnEditMode(false);
                                    }
                                  }}
                                  loading={loading}
                                />
                              </>
                            )}
                          </ATMTable.Cell>
                        </ATMTable.Row>
                      ))}
                  </ATMTable.Body>
                </ATMTable>
              </ATMGrid.Column>
            </ATMGrid.Row>
          </>
        )}
      </ATMGrid>
    </>
  );
};

const RealTimeLogForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleEnable,
  handleClick,
  setIsChangeLogUpdate,
  changeLogUpdateComplete,
  setChangeLogUpdateComplete,
  setChangeLogOnEditMode,
  loading,
  setTripOnEdit,
}) => (
  <ATMForm
    ref={formRef}
    onSubmit={handleSubmit}
    mode="onChange"
    defaultValues={RealTimeLogEntryFormSchema.cast({
      ...defaultValues,
      forcedLogSubs: (defaultValues?.forcedLogSubs || [])
        .map((v) => v.substationId)
        .filter(Boolean),
    })}
    validationSchema={RealTimeLogEntryFormSchema}
  >
    {(props) => {
      handleEnable(props.formState.isDirty);
      return (
        <ATMFormProvider {...props}>
          <FormContent
            handleClick={handleClick}
            setIsChangeLogUpdate={setIsChangeLogUpdate}
            changeLogUpdateComplete={changeLogUpdateComplete}
            setChangeLogUpdateComplete={setChangeLogUpdateComplete}
            setChangeLogOnEditMode={setChangeLogOnEditMode}
            loading={loading}
            defaultValues={
              RealTimeLogEntryFormSchema.cast({
                ...defaultValues,
                forcedLogSubs: (defaultValues?.forcedLogSubs || [])
                  .map((v) => v.substationId)
                  .filter(Boolean),
              }) as unknown as IRealTimeLog
            }
            setTripOnEdit={setTripOnEdit}
          />
        </ATMFormProvider>
      );
    }}
  </ATMForm>
);

export default RealTimeLogForm;
