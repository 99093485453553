/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { IRealTimeLog } from 'src/models/real-time-log.model';
import RealTimeLogBPORView from './real-time-log-bpor-report-view.component';

type IProps = {
  data: IRealTimeLog[];
  reportDate?: Date;
  version?: number | undefined;
  loading?: boolean;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  editorContent: string;
};

const RealTimeLogBPORReport: React.FC<IProps> = ({ data, reportDate, loading, editorContent, setEditorContent }) => {
  return (
    <>
    <RealTimeLogBPORView data={data} reportDate={reportDate} loading={loading} editorContent={editorContent} setEditorContent={setEditorContent} />
    </>
  );
};

export default RealTimeLogBPORReport;
