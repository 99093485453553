import React from 'react';
import {
  ATMGrid,
  ATMHeader,
  ATMSegment,
  ATMDivider,
  ATMDropdown,
  ATMButton,
} from 'shared-it-appmod-ui';
import { AccessRole } from 'src/constants/access.constant';
import { getIsAdmin, hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { IRealTimeLog } from 'src/models/real-time-log.model';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';
import { getUser } from 'src/libraries/amplify.library';
import { format24hTime, formatDate } from 'src/libraries/moment.library';
import RealTimeLogEdit from '../real-time-log-edit/real-time-log-edit.component';
import style from './real-time-log-in-progress.module.scss';
import RealTimeLogListEmailSend from '../real-time-log-list-email-send/real-time-log-list-email-send.component';
import RealTimeLogListEmailTurnoff from '../real-time-log-list-email-turnoff/real-time-log-list-email-turnoff.component';

type IProps = {
  data: IRealTimeLog[];
  count: number;
  loading: boolean;
  currentTab: number;
  timer?: any;
  refresh: () => void;
  showTableAll: boolean;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
  setUpdatedInProgressLog: React.Dispatch<React.SetStateAction<number | null>>;
  manualRefresh: boolean;
  setManualRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const RealTimeLogInProgressView: React.FC<IProps> = ({
  data,
  count,
  loading,
  timer,
  currentTab,
  refresh,
  showTableAll,
  setIsEditOpen,
  setUpdatedInProgressLog,
  manualRefresh,
  setManualRefresh,
}) => {
  return (
    <ATMSegment
      loading={(!data.length && loading) || (manualRefresh && loading)}
    >
      <ATMGrid.Row>
        <ATMGrid columns={2}>
          <ATMGrid.Column verticalAlign="middle" width={12}>
            <ATMHeader style={{ fontSize: '1em' }}>
              {Lang.formatString(Lang.TTL_REAL_TIME_LOG_IN_PROGRESS, count)}
            </ATMHeader>
          </ATMGrid.Column>
          <ATMGrid.Column width={4}>
            <ATMButton
              icon="refresh"
              onClick={() => {
                setManualRefresh(true);
                refresh();
              }}
              compact
            />
          </ATMGrid.Column>
        </ATMGrid>
      </ATMGrid.Row>

      {data.map((value) => {
        return (
          <div id="list" key={value.logId}>
            <ATMDivider />
            <ATMGrid.Row className={style.rowStyle}>
              <ATMGrid>
                <ATMGrid.Column width={11}>
                  <span className={style.emphasized}>
                    {value.facility && value.substation
                      ? `${value.substation?.name} - ${value.facility.volt.voltNm}kv - ${value.facility.outgFacNm}`
                      : value.facility?.outgFacNm}
                  </span>
                  <span className={style.inlineBlock}>
                    {`${format24hTime(value.logDtTm)} ${formatDate(
                      value.logDtTm
                    )}`}
                  </span>
                </ATMGrid.Column>
                <ATMGrid.Column width={5}>
                  <div
                    className={
                      getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) &&
                      (value.logCategory === RealTimeLogCategory.FORCEDOUTAGE ||
                        (value.logCategory !==
                          RealTimeLogCategory.FORCEDOUTAGE &&
                          getUser()?.emp_no ===
                            value.logUserId?.replace(/^00/gm, '')))
                        ? style.float
                        : style.hiddenEdit
                    }
                  >
                    <RealTimeLogEdit
                      data={value}
                      showTableAll={showTableAll}
                      currentTab={currentTab}
                      timer={timer}
                      setIsFormOpen={setIsEditOpen}
                      isFromInProgress
                      setUpdatedInProgressLog={setUpdatedInProgressLog}
                    />
                  </div>
                  <div
                    style={{ textAlign: 'center' }}
                    className={
                      hasRole(AccessRole.BPOR_SENDER) &&
                      value.logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
                      value.frcdOutgEmailSent !== false
                        ? style.floatPadded
                        : style.hiddenEdit
                    }
                  >
                    <ATMDropdown
                      pointing
                      icon="mail"
                      className={
                        value.frcdOutgEmailSent ? style.blue : style.red
                      }
                      closeOnChange
                      closeOnBlur
                    >
                      <ATMDropdown.Menu>
                        <RealTimeLogListEmailSend
                          data={value}
                          frcdOutgEmailSent={value.frcdOutgEmailSent}
                          isFromInProgress
                          setUpdatedInProgressLog={setUpdatedInProgressLog}
                        />
                        <RealTimeLogListEmailTurnoff
                          logId={value.logId}
                          isFromInProgress
                          setUpdatedInProgressLog={setUpdatedInProgressLog}
                        />
                      </ATMDropdown.Menu>
                    </ATMDropdown>
                  </div>
                </ATMGrid.Column>
              </ATMGrid>
            </ATMGrid.Row>
          </div>
        );
      })}
    </ATMSegment>
  );
};

export default RealTimeLogInProgressView;
