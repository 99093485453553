/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { StarterKitOptions } from '@tiptap/starter-kit';
import { BubbleMenu, Editor, EditorContent, Extension } from '@tiptap/react';
import { NoteEditor } from './note.editor';
import MenuBar from './menu-bar.component';
import styles from './editor.module.scss';

type IProps = {
    data?: any,
    editor: Editor,
    extensions: Extension<StarterKitOptions, any>[],
    withDate?: boolean,
    content: string,
    date?: Date,
    isVersionTwo?: boolean;
    isCustom?: boolean;
    children?: React.ReactNode;
    setDate?: React.Dispatch<React.SetStateAction<Date>>;
    setEditorContent?: React.Dispatch<React.SetStateAction<any>>;
    editorContent?: string;
};

const TextEditor: React.FC<IProps> = ({ content, editor, extensions, children, isCustom, editorContent }) => {
    if (!editor) {
        return null;
    }
  const noteEditor = new NoteEditor({
    editor,
    extensions,
    content,
    convertedContent: editorContent,
  });

  const textEditor = noteEditor?.getEditor().editor as unknown as Editor;
  return (
  <>
    {isCustom ? (children) : (
        <>
        <MenuBar editor={textEditor} />
        <div>
          <EditorContent editor={textEditor} className={styles.editor} />
            <BubbleMenu
              tippyOptions={{ duration: 150 }}
              editor={textEditor}
              shouldShow={({ from, to }) => {
                return from !== to;
              }}
            >
              <MenuBar editor={textEditor} isPopup />
            </BubbleMenu>
            </div>
          </>
    )}
  </>
  );
};

export default TextEditor;
