import yup from 'src/libraries/validator.library';
import { EmployeeSimpleShape } from './employee.model';

export const RealTimeLogAuditShape = {
  auditId: yup.number().required(),
  logId: yup.number().required(),
  auditType: yup.string().required(),
  auditEntry: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
  emp: yup.object(EmployeeSimpleShape).nullable(),
};

export const RealTimeLogAuditSimpleShape = {
  auditId: yup.number().required(),
  logId: yup.number().required(),
  auditType: yup.string().required(),
  auditEntry: yup.string().required(),
  emailContent: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const RealTimeLogAuditSchema = yup.object(RealTimeLogAuditShape);
// export const RealTimeLogAuditListSchema = yup.object(RealTimeLogAuditListShape);

export const RealTimeLogAuditPayloadSchema = yup.object({
  count: yup.object({
    all: yup.number(),
    today: yup.number(),
  }),
  rows: yup.array().of(RealTimeLogAuditSchema.clone()),
});
export const RealTimeLogAuditAllSchema = yup.object({
  auditType: yup.string(),
  updatedAt: yup.array().of(yup.date()).required(),
  updatedBy: yup.string(),
});

export type IRealTimeLogAudit = yup.Asserts<typeof RealTimeLogAuditSchema>;
export type IRealTimeLogAuditAll = yup.Asserts<
  typeof RealTimeLogAuditAllSchema
>;
