/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useTsoOssEmployeeContext } from 'src/contexts/tso-oss-employee.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import useStatusTab from 'src/hooks/status-tab.hook';
import Lang from 'src/libraries/language';
import {
  getRealTimeLogParams,
  getRealTimeLogStatus,
} from 'src/selectors/real-time-log.selector';
import {
  buildExportData,
  buildExportDataForRealTimeLog,
  convertToExcel,
} from 'src/selectors/file.selector';
import { checkValue } from 'src/libraries/common.library';
import Moment, {
  formatDate,
  format24hTime,
  format24hDateTime,
} from 'src/libraries/moment.library';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';
import RealTimeLogListView from './real-time-log-list.view';

const INTERVAL_SECONDS = 60;

const RealTimeLogList: React.FC = () => {
  const [isOpenEmployeeList, setIsOpenEmployeeList] = useState(false);
  const [isOpenBporReport, setIsOpenBporReport] = useState(false);
  const [isOpenAuditPage, setIsOpenAuditPage] = useState(false);
  const [reportDate, setReportDate] = useState<Date | null>(null);
  const { state, actions } = useRealTimeLogContext();
  const { actions: substationActions } = useSubstationContext();
  const { actions: tsoOssEmployeeActions } = useTsoOssEmployeeContext();
  const { currentTab } = useStatusTab('realTimeLogStatus');
  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_LIST_READ
  );
  const reportStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.BPOR_REPORT_READ
  );
  const formRef = useRef<HTMLFormElement>(null);
  const [showTableAll, setShowTableAll] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchFilters, setSearchFilters] = useState<Record<any, any>>({});
  const [showAll, setShowAll] = useState(true);
  const [previousTab, setPreviousTab] = useState<number>(0);
  // const [isFormOpen, setIsFormOpen] = useState<boolean | null>(null);
  const timer = useRef<NodeJS.Timeout>();
  const previousTabRef = useRef<number | null>(null);

  useEffect(() => {
    // Reset filters when currentTab changes
    if (currentTab === 0) {
      setShowTableAll(false);
      setSearchFilters({ page: 1, limit: 0 });
    } else {
      setSearchFilters({ page: 1, filters: [{ name: 'isAll', value: true }] });
    }

    actions.clearList();
    actions.employeeListGET();
  }, [currentTab]);

  useEffect(() => {
    previousTabRef.current = previousTab;
  }, [previousTab]);

  const handleFetch = useCallback(
    async (params) => {
      // if (isFormOpen === false) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      const data = getRealTimeLogParams(
        params.limit === 0
          ? { ...params }
          : {
              ...params,
              page: params.page,
              filters:
                previousTabRef.current === 1 ? undefined : params.filters,
              limit: 0,
            },
        currentTab
      );

      setPreviousTab(0);

      // The succeeding block of code should be before the call to backend (listGet)
      // This is important because the setting of timer cannot wait for listGet results
      // or it will cause some issues.
      if (currentTab === 0) {
        timer.current = setTimeout(() => {
          handleFetch(data);
        }, INTERVAL_SECONDS * 1000);
      }

      setSearchFilters(data);
      setIsSearch(false);
      const response = await actions?.listGET(data);

      if (response.error) {
        ToastError('Error retrieving Real-Time Logs.');
      }
      // }
    },
    [actions, currentTab]
  );

  const handleAll = useCallback(
    (params) => {
      const data = getRealTimeLogParams(params, currentTab);
      actions?.listGET(data);
    },
    [actions, currentTab]
  );

  const handleBporReport = useCallback(
    async (bporReportDate) => {
      // TODO: BPORTODO: Check if date is correct
      // console.log('reportDate: ', reportDate);
      const result = await actions?.reportGET(bporReportDate);
      if (result.error) {
        // ToastError('Error retrieving BPOR report entries.');
        ToastError('Error retrieving BPOR report logs.');
      } else {
        // BPORTODO dosomething about the report. show preview
        // setShowBporPreview(true);
      }
    },
    [actions]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      setIsSearch(true);
      formRef.current.handleSubmit();
    } else {
      setIsSearch(false);
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData) => {
      if (isSearch) {
        const data = getRealTimeLogParams(
          {
            page: 1,
            filters: Object.entries(formData).map(([key, value]) => {
              return {
                name: key,
                value,
              };
            }),
          },
          1
        );
        setSearchFilters(data);
        setShowTableAll(true);
        const response = await actions?.listGET(data);

        if (response.error) {
          ToastError('Error retrieving Real-Time Logs.');
        }
      }
    },
    [actions, formRef, showTableAll, isSearch]
  );

  useEffect(() => {
    if (!state.line || state.line?.length === 0) {
      actions.lineGET();
    }
    substationActions?.listSubstationCreateGET({ limit: 0, page: 1 });

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      actions.clearList();
    };
  }, [actions]);

  const handleTsoOssDownload = useCallback(async () => {
    const result = await tsoOssEmployeeActions.listGET();

    if (result.payload?.rows) {
      const list = result.payload.rows;

      const items = list.map((value) => [
        checkValue(value.fullName ?? '--'),
        checkValue(value.userType.toUpperCase() ?? '--'),
        checkValue(
          value.userType.toUpperCase() === 'TSO'
            ? 'Transmission System Operator'
            : 'Operations Shift Supervisor'
        ),
      ]);
      const { exportData, format } = convertToExcel(
        buildExportData(items, [
          Lang.LBL_FULLNAME,
          Lang.LBL_TSO_OSS,
          Lang.LBL_TSO_OSS_EMPLOYEE_TITLE,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_TSO-OSS-Employee_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions]);

  const handleDownload = useCallback(async () => {
    const response = await actions?.listGET({ ...searchFilters, limit: 0 });

    // US #628758 new report style
    if (response.payload?.rows) {
      const list = response.payload?.rows;

      const items = list.map((value) => {
        const logEntry = value.logEntry?.replaceAll('\n', '<br/>');
        let facility =
          value.facility && value.substation
            ? value.substation?.name
            : value.facility?.outgFacNm ?? '';
        facility = facility ? `<b>${facility}</b><br/>` : '';

        let description = '';

        if (value.logCategory === RealTimeLogCategory.FORCEDOUTAGE) {
          const occurence = `<b>Occurence: </b>${
            value.logDtTmFrcdOutOcurred
              ? format24hDateTime(value.logDtTmFrcdOutOcurred)
              : ''
          }`;
          const restoration = `<b>Restoration: </b>${
            value.logDtTmFrcdOutRestored
              ? format24hDateTime(value.logDtTmFrcdOutRestored)
              : ''
          }`;
          let subs = '--';
          if (value.forcedLogSubs && value.forcedLogSubs.length > 0) {
            subs = '';
            value.forcedLogSubs.map((val, key) => {
              subs += val.substation.name;
              if (value.forcedLogSubs && key < value.forcedLogSubs.length - 1) {
                subs = `${subs}, `;
              }
            });
          }
          const subsInterrupted = `<b>Subs Interrupted: </b><br/>${subs}`;

          let trips = '--';
          if (value.forcedLog && value.forcedLog.length > 0) {
            trips = '';
            const { length } = value.forcedLog;
            value.forcedLog.map((val, i) => {
              trips += `<i>${val.substation.name}:</i> ${val.tripsTargets}`;
              if (i < length - 1) {
                trips += '<br/>';
              }
            });
          }
          const tripsAndTargets = `<b>Trips and Targets: </b><br/>${trips}`;

          let logs = '--';
          if (value.changeLog && value.changeLog.length > 0) {
            logs = '';
            value.changeLog.map((val) => {
              const empUpdater = val.emp?.lastName
                ? `${val.emp?.lastName}, ${val.emp?.firstName}`
                : val.updatedBy;

              logs += `${format24hDateTime(val.updatedAt)} | ${empUpdater} | ${
                val.changeLogNote
              }<br/>`;
            });
          }
          const additionalLogs = `<b>Addtional Logs: </b><br/>${logs}`;

          description = `${facility}${logEntry}<br/><br/>${occurence}<br/>${restoration}<br/><br/>${subsInterrupted}<br/><br/>${tripsAndTargets}<br/><br/>${additionalLogs}`;
        } else {
          description = `${facility}${logEntry}`;
        }

        // US #628758 new report style
        if (description.startsWith('<br/>')) {
          description = description.substring(description.indexOf('<br/>'));
        }
        description = description.replace(
          /<br\/?>/g,
          '<br style="mso-data-placement:same-cell;"/>'
        );

        return [
          checkValue(formatDate(value.logDtTm)),
          checkValue(format24hTime(value.logDtTm)),
          checkValue(
            `${
              value.emp.lastName && value.emp.firstName
                ? `${value.emp.lastName}, ${value.emp.firstName}`
                : 'Unavailable'
            } / ${value.logUserType} User`
          ),
          checkValue(value.logCategory ?? '--'),
          checkValue(value.facility?.outgFacNm ?? '--'),
          checkValue(value.substation?.name ?? '--'),
          checkValue(value.facility?.volt.voltNm ?? '--'),
          checkValue(description),
          checkValue(value.logBporReport === true ? 'YES' : 'NO'),
        ];
      });
      const { exportData, format } = convertToExcel(
        buildExportDataForRealTimeLog(items, [
          Lang.LBL_DATE,
          Lang.LBL_TIME,
          Lang.LBL_NAME,
          Lang.LBL_CATEGORY,
          Lang.LBL_FACILITY,
          Lang.LBL_SUBSTATION,
          Lang.LBL_VOLTAGE,
          Lang.LBL_DESCRIPTION,
          Lang.LBL_BPOR,
        ])
      );
      const link = document.createElement('a');
      link.setAttribute('href', exportData);
      link.setAttribute(
        'download',
        `MCC_Real_Time_Log_${Moment().format('YYYYMMDD')}.${format}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [actions, searchFilters]);

  return (
    <RealTimeLogListView
      data={state.list}
      loading={status.fetching}
      reportLoading={reportStatus.fetching}
      handleFetch={handleFetch}
      handleAll={handleAll}
      total={state.all}
      today={state.today}
      currentTab={currentTab}
      isOpenEmployeeList={isOpenEmployeeList}
      isOpenBporReport={isOpenBporReport}
      isOpenAuditPage={isOpenAuditPage}
      handleOpenEmployeeList={setIsOpenEmployeeList}
      handleOpenBporReport={setIsOpenBporReport}
      handleOpenAuditPage={setIsOpenAuditPage}
      formRef={formRef}
      handleSubmit={handleSubmit}
      handleClick={handleClick}
      handleTsoOssDownload={handleTsoOssDownload}
      showTableAll={showTableAll}
      setShowTableAll={setShowTableAll}
      employees={state.employeeList}
      handleDownload={handleDownload}
      setIsSearch={setIsSearch}
      searchFilters={searchFilters}
      setShowAll={setShowAll}
      showAll={showAll}
      setSearchFilters={setSearchFilters}
      timer={timer}
      handleBporReport={handleBporReport}
      reportDate={reportDate as any}
      setReportDate={setReportDate}
      // isFormOpen={isFormOpen}
      // setIsFormOpen={setIsFormOpen}
      setPreviousTab={setPreviousTab}
    />
  );
};

export default RealTimeLogList;
